import logoToyota from '../images/logo-toyota.svg'
import logoLexus from '../images/LFS logo horizontal 2D Black.jpg'
import React, { useEffect, useState } from 'react'
import { isLexusUrl } from 'utils/utils'

const Header = () => {

  const [logo, setLogo] = useState("")

  useEffect(()=> {
    //Qui entra solo quando cambia il valore del flag
    const handlerLocalStorageChange = ()  => {
      
    let url = window.location.href;
    isLexusUrl(url).then(isLexus => {
    if(isLexus) {
      setLogo(logoLexus)
    } else {
      setLogo(logoToyota)
    }
  })
    }
    window.addEventListener("storage", handlerLocalStorageChange)
    
    let url = window.location.href;
    isLexusUrl(url).then(isLexus => {
    if(isLexus) {
      setLogo(logoLexus)
    } else {
      setLogo(logoToyota)
    }
  })
    return () => window.removeEventListener('storage', handlerLocalStorageChange)
  }, [])

  return (
    <div style={{ background: '#fff' }} className="sticky top-0 pt-5 mb-5">
      <img src={logo} alt="Logo" className="h-10" />
    </div>
  )
}

export default Header

/* eslint-disable camelcase */
/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { SubscribedDocuments } from 'commons/dto/legalDoc'
import { InstallmentDetailResponse } from 'commons/dto/payment'
import { CreateSubscriptionResponse } from 'commons/dto/subscription'
import { getActiveWorkOrdersResponse } from 'commons/workOrders'
import { NavigateFunction } from 'react-router-dom'
import { RootState } from 'store'
export type Card = {
  cardId: number
  status: string
  brand: string
  type: string
  lastDigits: string
  expiryMonth: number
  expiryYear: number
}
export type UserState = {
  misc: {
    loading: boolean
    error: boolean
  }
  tempUser: {
    loading: boolean
    error: boolean
    errorMessage: string
    id: string
  }
  isLoggedIn: boolean
  decryptPassphrase: string
  userInfo: {
    userId: string
    preferred_username: string
    email: string
    email_verified: boolean
    given_name: string
    name: string
    surname: string
    dateOfBirth: string
    placeOfBirth: string
    nationality: string
    taxCode: string
  }
  addressInfo: {
    addressId: string
    defaultFlag: boolean
    city: string
    country: string
    postalCode: string
    streetAddress: string
    streetNumber: string
  }
  phoneInfo: {
    entityId: string
    phoneNumber: string
  }
  cards: Array<Card>
  currentSubscription: CreateSubscriptionResponse
  associatedWorkOrders: Array<
    getActiveWorkOrdersResponse & { detail?: InstallmentDetailResponse }
  >
  subscribedInformativeDocuments: SubscribedDocuments
}

export const initialState: UserState = {
  misc: {
    loading: false,
    error: false,
  },
  tempUser: {
    loading: false,
    error: false,
    errorMessage: '',
    id: '',
  },
  isLoggedIn: false,
  decryptPassphrase: '',
  userInfo: {
    userId: '',
    preferred_username: '',
    email: '',
    email_verified: false,
    given_name: '',
    name: '',
    surname: '',
    dateOfBirth: '',
    placeOfBirth: '',
    nationality: '',
    taxCode: '',
  },
  addressInfo: {
    addressId: '',
    defaultFlag: false,
    city: '',
    country: '',
    postalCode: '',
    streetAddress: '',
    streetNumber: '',
  },
  phoneInfo: {
    entityId: '',
    phoneNumber: '',
  },
  cards: [],
  currentSubscription: null,
  associatedWorkOrders: [],
  subscribedInformativeDocuments: { documents: [] },
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    logOutRequest(
      _state,
      _action: PayloadAction<{
        targetPath?: string
        navigate: NavigateFunction
      }>,
    ) {},
    logOut(state) {
      state.isLoggedIn = false
      state.decryptPassphrase = null
      state.userInfo = {
        userId: '',
        preferred_username: '',
        email: '',
        email_verified: false,
        given_name: '',
        name: '',
        surname: '',
        dateOfBirth: '',
        placeOfBirth: '',
        nationality: '',
        taxCode: '',
      }
      state.addressInfo = {
        addressId: '',
        defaultFlag: false,
        city: '',
        country: '',
        postalCode: '',
        streetAddress: '',
        streetNumber: '',
      }
      state.phoneInfo = {
        entityId: '',
        phoneNumber: '',
      }
    },
    setTempUserIdRequest(
      state,
      _action: PayloadAction<{ navigate: NavigateFunction }>,
    ) {
      state.tempUser.loading = true
      state.tempUser.error = false
      state.tempUser.errorMessage = null
    },
    setTempUserIdSucceded(state, action: PayloadAction<string>) {
      state.tempUser.loading = false
      state.tempUser.error = false
      state.tempUser.id = action.payload
    },
    setTempUserIdFailed(state, action: PayloadAction<string>) {
      state.tempUser.loading = false
      state.tempUser.error = true
      state.tempUser.errorMessage = action.payload
    },
    confirmTempUserId(state, action: PayloadAction<string>) {
      state.userInfo.userId = action.payload
    },
    setUserEmail(state, action: PayloadAction<{ email: string }>) {
      const { email } = action.payload
      state.userInfo.email = email
    },
    setUserPhone(
      state,
      action: PayloadAction<{ phoneNumber: string; entityId: string }>,
    ) {
      const { phoneNumber, entityId } = action.payload
      state.phoneInfo = {
        entityId,
        phoneNumber,
      }
    },
    login(
      state,
      _action: PayloadAction<{
        email: string
        password: string
        navigate: NavigateFunction
        urlParameters: string | null
        token: string | {access_token: string} | null
      }>,
    ) {
      state.isLoggedIn = true
      state.misc = {
        loading: true,
        error: false,
      }
    },
    loginSucceeded(
      state,
      action: PayloadAction<{
        decryptPassphrase: string
        userInfo: UserState['userInfo']
        addressInfo: UserState['addressInfo']
        phoneInfo: UserState['phoneInfo']
        navigate: NavigateFunction
      }>,
    ) {
      state.misc = {
        loading: false,
        error: false,
      }
      state.isLoggedIn = true
      state.decryptPassphrase = action.payload.decryptPassphrase
      state.userInfo = action.payload.userInfo
      state.addressInfo = action.payload.addressInfo
      state.phoneInfo = action.payload.phoneInfo
    },
    loginFailed(state) {
      state.isLoggedIn = false
      state.misc = {
        loading: false,
        error: true,
      }
    },
    updateUserInfo(
      state,
      action: PayloadAction<Partial<UserState['addressInfo']>>,
    ) {
      state.userInfo = {
        ...state.userInfo,
        ...action.payload,
      }
    },
    updateAddressInfo(
      state,
      action: PayloadAction<Partial<UserState['addressInfo']>>,
    ) {
      state.addressInfo = { ...state.addressInfo, ...action.payload }
    },
    setUserCards(state, action: PayloadAction<Array<Card>>) {
      state.cards = action.payload
    },
    emptyUserCards(state) {
      state.cards = []
    },
    updateUserCard(state, action: PayloadAction<Card>) {
      const cardIndex = state.cards.findIndex(
        card => card.cardId === action.payload.cardId,
      )
      if (cardIndex !== -1) {
        state.cards[cardIndex] = action.payload
      }
    },
    setUserMiscLoading(state, action: PayloadAction<boolean>) {
      state.misc.loading = action.payload
    },
    createSubscriptionSuccess(state, action: PayloadAction<any>) {
      state.currentSubscription = action.payload
    },
    setUserActiveWorkOrders(
      state,
      action: PayloadAction<getActiveWorkOrdersResponse[]>,
    ) {
      state.associatedWorkOrders = action.payload
    },
    setDetailUserActiveWorkOrders(
      state,
      action: PayloadAction<Array<InstallmentDetailResponse>>,
    ) {
      const workOrders = action.payload
      for (const workOrder of workOrders) {
        const index = state.associatedWorkOrders.findIndex(
          item => item.workOrderId === workOrder.workOrderId,
        )
        if (index !== -1) {
          const old = { ...state.associatedWorkOrders[index] }
          state.associatedWorkOrders[index] = { ...old, detail: workOrder }
        }
      }
    },
    setSubscribedInformativeDocuments(
      state,
      action: PayloadAction<SubscribedDocuments>,
    ) {
      state.subscribedInformativeDocuments = action.payload
    },
    resetToInitialStateUser(state) {
      state.isLoggedIn = false
      state.decryptPassphrase = null
      state.userInfo = {
        userId: '',
        preferred_username: '',
        email: '',
        email_verified: false,
        given_name: '',
        name: '',
        surname: '',
        dateOfBirth: '',
        placeOfBirth: '',
        nationality: '',
        taxCode: '',
      }
      state.addressInfo = {
        addressId: '',
        defaultFlag: false,
        city: '',
        country: '',
        postalCode: '',
        streetAddress: '',
        streetNumber: '',
      }
      state.phoneInfo = {
        entityId: '',
        phoneNumber: '',
      }
      ;(state.cards = []), (state.currentSubscription = null)
      ;(state.misc = {
        loading: false,
        error: false,
      }),
        (state.tempUser = {
          loading: false,
          error: false,
          errorMessage: '',
          id: '',
        }),
        (state.isLoggedIn = false),
        (state.decryptPassphrase = '')
    },
  },
})


export const selectIsLoggedIn = (state: RootState): boolean =>
  state.user.isLoggedIn
export const selectUserId = (state: RootState): string =>
  state.user.userInfo.userId
export const selectTempUser = (state: RootState): UserState['tempUser'] =>
  state.user.tempUser
export const selectUserEmail = (state: RootState): string =>
  state.user.userInfo.email
export const selectUserPhone = (state: RootState): UserState['phoneInfo'] =>
  state.user.phoneInfo
export const selectUserInfo = (state: RootState): UserState['userInfo'] =>
  state.user.userInfo
export const selectAddressInfo = (state: RootState): UserState['addressInfo'] =>
  state.user.addressInfo
export const selectDecryptPassphrase = (
  state: RootState,
): UserState['decryptPassphrase'] => state.user.decryptPassphrase
export const selectUserCards = (state: RootState): UserState['cards'] =>
  state.user.cards
export const selectUserMiscLoading = (state: RootState): boolean =>
  state.user.misc.loading
export const selectCurrentSubscription = (state: RootState): any =>
  state.user.currentSubscription
export const selectActiveWorkOrders = (
  state: RootState,
): Array<
  getActiveWorkOrdersResponse & { detail?: InstallmentDetailResponse }
> => state.user.associatedWorkOrders
export const selectInProgressActiveWorkOrders = (
  state: RootState,
): Array<
  getActiveWorkOrdersResponse & { detail?: InstallmentDetailResponse }
> =>
  state.user.associatedWorkOrders?.filter(
    workOrder => workOrder?.status === 'IN_PROGRESS',
  )
export const selectActiveUserCards = (state: RootState): Array<Card> =>
  state.user.cards?.filter(card => card.status === 'ACTIVE')

export const selectPaidInstalments = (state: RootState) =>
  state.user.associatedWorkOrders.map(workOrder => ({
    ...workOrder,
    paidInstalments: workOrder?.detail?.installment?.filter(
      instalment => instalment?.status === 'PAID',
    ),
  }))
export const selectSubscribedInformativeDocuments = (state: RootState) =>
  state.user.subscribedInformativeDocuments.documents
export const {
  logOut,
  logOutRequest,
  setTempUserIdRequest,
  setTempUserIdSucceded,
  setTempUserIdFailed,
  confirmTempUserId,
  setUserEmail,
  setUserPhone,
  login,
  loginSucceeded,
  loginFailed,
  updateUserInfo,
  updateAddressInfo,
  setUserCards,
  emptyUserCards,
  updateUserCard,
  setUserMiscLoading,
  createSubscriptionSuccess,
  resetToInitialStateUser,
  setUserActiveWorkOrders,
  setDetailUserActiveWorkOrders,
  setSubscribedInformativeDocuments,
} = userSlice.actions
export default userSlice.reducer

import { workOrdersApi } from 'api/easypay'
import { Paragraf } from 'components'
import GoBackButton from 'components/GoBackButton'
import Title from 'components/Title'
import GridLayout from 'layout/GridLayout'
import React, { useEffect, useMemo, useState } from 'react'
import { FaDownload } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  CUSTOMER_AREA_HOMEPAGE,
  CUSTOMER_AREA_PAYMENTS_SITUATION,
} from 'routes/constants'
import { requestUserActiveWorkOrders } from 'store/actions'
import { selectActiveWorkOrders } from 'store/slices/userSlice'
import { classNames } from 'utils/css'
import { printToast } from 'utils/printToast'
import { isLexusCss,isLexusUrl } from 'utils/utils'


  let urlisLexus = []
  let url = window.location.href;
  isLexusCss(url).then(isLexus => {
      urlisLexus=isLexus
  })



const PaymentsSituationOverview: React.VFC = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const workOrders = useSelector(selectActiveWorkOrders)
  const [downloadButtonDisabled, setDownloadButtonDisabled] = useState(false)
  


  const [css, setCss] = useState("")
  const [title, setTitle] = useState("")
  const [desktopImage, setDesktopImage] = useState("")
  const [mobileImage, setMobileImage] = useState("")
  useEffect(()=> {
    let url = window.location.href;
    isLexusUrl(url).then(isLexus => {
      if(isLexus) {
        setCss("bg-lexusBlue shadow-leuxsCard")
        setTitle("Lexus EASY PAY")
        setMobileImage("/lexusImages/lexusImage01-mobile.jpg")
        setDesktopImage("/lexusImages/lexusImage01-desktop.jpg")
      } else {
        setTitle("TOYOTA EASY PAY")
        setCss("bg-toyotaRed shadow-toyotaCard")
        setMobileImage("/assets/images/riepilogo_pagamento-mobile.png")
        setDesktopImage("/assets/images/riepilogo_pagamento-desktop.png")
      }
    })
  }, [])

  



  
  const PaymentStatusMap = new Map([
      [
        'PAID',
        { name: 'Saldato', bgcolor: 'bg-toyotaGray', color: 'text-primary' },
      ],
      [
        'IN_PROGRESS',
        {
          name: 'In corso',
          bgcolor: css,
          color: 'text-white',
        },
      ],
      [
        'FALLBACK',
        {
          name: '',
          bgcolor: css,
          color: 'text-white',
        },
      ],
      [
        'PENDING',
        {
          name: 'Da pagare',
          bgcolor: css,
          color: 'text-white',
        },
      ],
      [
        'REFUNDED',
        {
          name: 'Rimborsato',
          bgcolor: 'bg-toyotaGray',
          color: 'text-primary',
        },
      ],
    ])



  useEffect(() => {
    dispatch(requestUserActiveWorkOrders({}))
  }, [])

  const goToWorkOrderDetail = (selectedWorkorderId: string) => {
    navigate(
      `/customer-area/${CUSTOMER_AREA_PAYMENTS_SITUATION}/${selectedWorkorderId}`,
    )
  }
  const getParagraphText = useMemo(() => {
    return workOrders.length > 0
      ? workOrders.length > 1
        ? 'seleziona un piano rateale per visionarne il dettaglio'
        : 'seleziona il piano rateale per visionarne il dettaglio'
      : 'nessun piano rateale attivo'
  }, [workOrders])

  function base64ToBlob(base64, contentType) {
    const binaryString = window.atob(base64)
    const len = binaryString.length
    const bytes = new Uint8Array(len)
    for (let i = 0; i < len; ++i) {
      bytes[i] = binaryString.charCodeAt(i)
    }

    return new Blob([bytes], {
      type: contentType === 'IMAGE' ? 'image/jpg' : 'application/pdf',
    })
  }

  async function downloadWorkOrder(workOrderId) {
    setDownloadButtonDisabled(true)
    workOrdersApi
      .downloadWorkOrder({
        workOrderId: workOrderId,
      })
      .then(response => {
        if (response.data.base64 != undefined) {
          const blob = base64ToBlob(
            response.data.base64,
            response.data.contentType,
          )
          const blobURL = URL.createObjectURL(blob)
          const link = document.createElement('a')
          link.setAttribute(
            'download',
            response.data.contentType === 'IMAGE'
              ? workOrderId.split('/')[0] + '.jpg'
              : workOrderId.split('/')[0] + '.pdf',
          )
          link.href = blobURL
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
        } else {
          printToast.error('File non esistente')
        }
        setDownloadButtonDisabled(false)
      })
      .catch(error => {
        printToast.error('File non esistente')
        setDownloadButtonDisabled(false)

        console.error(error)
      })
  }

  const handleDownloadWorkOrder = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    workOrder: typeof workOrders[0],
  ) => {
    e.stopPropagation()
    downloadWorkOrder(workOrder.workOrderId + '/' + workOrder.dealerCode)
  }

  return (
    <div className="mb-2 text-center w-full pb-5">
      <GridLayout
        cssProps="place-items-center"
        mobileUrl = {mobileImage}
        desktopUrl = {desktopImage}
      >
        <Title
          name = {title}
          cssProps="text-xl md:text-4xl md:text-left"
        />
        <Paragraf
          name="Situazione pagamenti"
          cssProps="mb-2 text-xl text-center md:text-left"
        />
        <div className="mt-5 mb-10">
          <Paragraf
            name={getParagraphText}
            cssProps={`remove-empty-content pb-4 font-${urlisLexus[0]}  text-center md:text-left `}
          />
          <div className="grid place-items-center space-y-3 my-2">
            {workOrders.map((workOrder, idx) => (
              <React.Fragment key={idx}>
                <button
                  onClick={() => goToWorkOrderDetail(workOrder.workOrderId)}
                  key={workOrder.workOrderId}
                  className="justify-self-stretch"
                >
                  <div
                    className={classNames(
                      'min-w-full flex justify-between items-center py-6 px-8 rounded-3xl shadow-md',
                      PaymentStatusMap.get(workOrder.status)?.bgcolor ||
                        PaymentStatusMap.get('FALLBACK')?.bgcolor,
                    )}
                  >
                    <div>
                      <p
                        className={classNames(
                          `text-left  font-${urlisLexus[0]}  uppercase`,
                          PaymentStatusMap.get(workOrder.status)?.color ||
                            PaymentStatusMap.get('FALLBACK')?.color,
                        )}
                      >
                        {'workOrder' in workOrder ? 'Ordine' : 'Fattura'}
                      </p>
                      <p
                        className={classNames(`font-${urlisLexus[0]} `,
                          PaymentStatusMap.get(workOrder.status)?.color ||
                            PaymentStatusMap.get('FALLBACK')?.color,
                        )}
                      >
                        {'workOrder' in workOrder
                          ? workOrder.workOrder
                          : workOrder.invoice}
                      </p>
                    </div>
                    <div>
                      <p
                        className={classNames(
                          `font-${urlisLexus[0]}  uppercase`,
                          PaymentStatusMap.get(workOrder.status)?.color ||
                            PaymentStatusMap.get('FALLBACK')?.color,
                        )}
                      >
                        Stato
                      </p>
                      <p
                        className={classNames(`font-${urlisLexus[0]} `,
                          PaymentStatusMap.get(workOrder.status)?.color ||
                            PaymentStatusMap.get('FALLBACK')?.color,
                        )}
                      >
                        {PaymentStatusMap.get(workOrder.status)?.name ||
                          workOrder.status}
                      </p>
                    </div>

                    <div>
                      <button
                        title="scarica documento"
                        disabled={downloadButtonDisabled}
                        onClick={e => handleDownloadWorkOrder(e, workOrder)}
                      >
                        <FaDownload />
                        <span className="sr-only">scarica documento</span>
                      </button>
                    </div>
                  </div>
                </button>
              </React.Fragment>
            ))}
          </div>
        </div>
        <GoBackButton
          cssProps={`py-2 px-5 md:px-0 flex space-x-2 justify-center md:justify-start items-center font-${urlisLexus[0]}  mx-auto md:mx-0 w-64  `}
          handleClick={() =>
            navigate(`/customer-area/${CUSTOMER_AREA_HOMEPAGE}`)
          }
        />
      </GridLayout>
    </div>
  )
}

export default PaymentsSituationOverview

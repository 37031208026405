import axios, { AxiosResponse } from 'axios'
import { easyPayInstance } from 'Axios/AxiosInstance'
import { InstallmentDetailResponse } from 'commons/dto/payment'
import { getBrandByURL } from 'utils/utils'

  let brand = ""
  let appId = "TOYOTA"
  let url = window.location.href;
  
  getBrandByURL(url).then(brand_value => {
    brand = brand_value;
  })

type oneShotPaymentResponse = {
  redirectUrl: string
}

export const oneShotPayment = ({
  description,
  workOrderId,
  dealerCode,
}: {
  description: string
  workOrderId: string
  dealerCode: string
}): Promise<AxiosResponse<oneShotPaymentResponse>> => {
  return easyPayInstance.post(`/${appId}/payment/`, {
    description,
    workOrderId,
    dealerCode,
  })
}

export const getInstallmentDetail = ({
  subscriptionId,
}: {
  subscriptionId: string
}): Promise<AxiosResponse<InstallmentDetailResponse>> => {
  return easyPayInstance.get(`/${appId}/customer/subscription/${subscriptionId}?caller=EASYPAY`)
}
export const payInstalment = ({
  workOrderId,
  installmentId,
  paymentMethodId,
  customerId,
  dealerCode,
}: {
  workOrderId: string
  installmentId: number
  paymentMethodId: number
  customerId: string
  dealerCode: string
}): Promise<AxiosResponse<any>> => {
  return easyPayInstance.post(`/${appId}/payment/installment`, {
    workOrderId,
    installmentId,
    paymentMethodId,
    customerId,
    dealerCode,
    brand,
  })
}

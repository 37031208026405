import { informativeDocumentsApi, tempUserApi } from 'api/easypay'
import { Paragraf } from 'components'
import GoBackButton from 'components/GoBackButton'
import Title from 'components/Title'
import GridLayout from 'layout/GridLayout'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { FaDownload } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { CUSTOMER_AREA_HOMEPAGE } from 'routes/constants'
import { requestInformativeDocuments } from 'store/actions'
import { selectSubscribedInformativeDocuments } from 'store/slices/userSlice'
import { classNames } from 'utils/css'
import { printToast } from 'utils/printToast'
import { isLexusCss,isLexusUrl } from 'utils/utils'


  let urlisLexus = []
  let url = window.location.href;
  isLexusCss(url).then(isLexus => {
      urlisLexus=isLexus
  })

const InformativeDocumentsMap = new Map([
  ['TERMS', 'termini e condizioni'],
  ['PRIVACY', 'Privacy'],
])

const InformativeDocuments: React.VFC = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const subscribedInformativeDocuments = useSelector(
    selectSubscribedInformativeDocuments,
  )
  const [downloadButtonDisabled, setDownloadButtonDisabled] = useState(false)
  
  
  const [title, setTitle] = useState("")
  const [desktopImage, setDesktopImage] = useState("")
  const [mobileImage, setMobileImage] = useState("")
  useEffect(()=> {
    let url = window.location.href;
    isLexusUrl(url).then(isLexus => {
      if(isLexus) {
        setTitle("Lexus EASY PAY")
        setMobileImage("/lexusImages/lexusImage01-mobile.jpg")
        setDesktopImage("/lexusImages/lexusImage01-desktop.jpg")
      } else {
        setTitle("TOYOTA EASY PAY")
        setMobileImage("/assets/images/riepilogo_pagamento-mobile.png")
        setDesktopImage("/assets/images/riepilogo_pagamento-desktop.png")
      }
    })
  }, [])



 

  useEffect(() => {
    dispatch(requestInformativeDocuments())
  }, [])

  function base64ToBlob(base64, contentType) {
    const binaryString = window.atob(base64)
    const len = binaryString.length
    const bytes = new Uint8Array(len)
    for (let i = 0; i < len; ++i) {
      bytes[i] = binaryString.charCodeAt(i)
    }

    return new Blob([bytes], {
      type: contentType === 'IMAGE' ? 'image/jpg' : 'application/pdf',
    })
  }

  async function DownloadSubscribedDocument({
    documentId,
    userId,
    docType,
    version,
  }: {
    documentId: string
    userId: string
    docType: string
    version: string
  }) {
    setDownloadButtonDisabled(true)
    informativeDocumentsApi
      .downloadSubscribedDocument({
        documentId,
        userId,
      })
      .then(response => {
        if (response.data.docBase64 != undefined) {
          const blob = base64ToBlob(
            response.data.docBase64,
            response.data.extension,
          )
          const blobURL = URL.createObjectURL(blob)
          const link = document.createElement('a')
          link.setAttribute('download', `${docType}-${version}` + '.pdf')
          link.href = blobURL
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
        } else {
          printToast.error('File non esistente')
        }
        setDownloadButtonDisabled(false)
      })
      .catch(error => {
        printToast.error('File non esistente')
        setDownloadButtonDisabled(false)

        console.error(error)
      })
  }
  const user = JSON.parse(sessionStorage.getItem('user') || '{}')
  const handleDownloadSubscribedDocument = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    document: typeof subscribedInformativeDocuments[0]['document'],
  ) => {
    e.stopPropagation()
    DownloadSubscribedDocument({
      documentId: document.id,
      userId: user.userInfo?.userId,
      docType: document.name,
      version: document.version
    })
  }

  return (
    <div className="mb-2 text-center w-full pb-5">
      <GridLayout
        cssProps="place-items-center"
        mobileUrl = {mobileImage}
        desktopUrl = {desktopImage}
      >
        <Title
          name={title}
          cssProps="text-xl md:text-4xl md:text-left"
        />
        <Paragraf
          name="Documenti sottoscritti"
          cssProps="mb-2 text-xl text-center md:text-left"
        />
        <div className="mt-5 mb-10">
          <div className="grid place-items-center space-y-3 my-2">
            {subscribedInformativeDocuments.map((document, idx) => (
              <React.Fragment key={idx}>
                <div
                  key={document.document.id}
                  className="justify-self-stretch"
                >
                  <div
                    className={classNames(
                      'min-w-full flex justify-between items-center py-6 px-8 rounded-3xl shadow-md',
                    )}
                  >
                    <div className="w-min">
                      <p
                        className={classNames(
                          `text-left  font-${urlisLexus[0]} capitalize`,
                        )}
                      >
                        {InformativeDocumentsMap.get(document.document.name)}
                      </p>
                      <p className={classNames(`font-${urlisLexus[0]}`)}>
                        {/* {'invoice' in workOrder
                          ? workOrder.invoice
                          : workOrder.workOrder} */}
                      </p>
                    </div>
                    <div>
                      <p
                        className={classNames(
                          `font-${urlisLexus[0]} uppercase`,
                        )}
                      >
                        Data
                      </p>
                      <p className={classNames(`font-${urlisLexus[0]}`)}>
                        {moment(document.subscriptionDate).format('DD/MM/YYYY')}
                      </p>
                    </div>

                    <div>
                      <button
                        title="scarica documento"
                        disabled={downloadButtonDisabled}
                        onClick={e =>
                          handleDownloadSubscribedDocument(e, document.document)
                        }
                      >
                        <FaDownload />
                        <span className="sr-only">scarica documento</span>
                      </button>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            ))}
          </div>
        </div>
        <GoBackButton
          cssProps={`py-2 px-5 md:px-0 flex space-x-2 justify-center md:justify-start items-center font-${urlisLexus[0]}  mx-auto md:mx-0 w-64  `}
          handleClick={() =>
            navigate(`/customer-area/${CUSTOMER_AREA_HOMEPAGE}`)
          }
        />
      </GridLayout>
    </div>
  )
}

export default InformativeDocuments

import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { populateWorkOrder } from 'store/slices/appMiscSlice'
import { WorkOrder, WorkOrderStatus } from 'store/types/workOrder'
import numbersUtil from 'utils/numberFormatUtils'
import CommonButton from '../CommonButton'
import Paragraf from '../Paragraf'
import { classNames } from 'utils/css'
import { isLexusUrl,isLexusCss } from 'utils/utils'


  let urlisLexus = []
  let url = window.location.href;
  isLexusCss(url).then(isLexus => {
    urlisLexus=isLexus
  })


type Props = {
  workOrder: WorkOrder | Record<string, never>
  workOrderStatus: WorkOrderStatus
  onPayHandler: VoidFunction
  loadingApi?: boolean
  splittedPayment?: any
  isOverview?: boolean
}

const SplitPayments: React.FC<Props> = ({
  splittedPayment,
  workOrder,
  workOrderStatus,
  loadingApi,
  onPayHandler,
  isOverview,
}) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  React.useEffect(() => {
    if (!workOrder) {
      console.error('No workOrder defined')
      navigate('/')
    }
  }, [navigate, workOrder])

  const handleNewOperation = async () => {
    await dispatch(populateWorkOrder({ workOrder: null, status: null }))
    navigate('/')
  }

 
  const [backgroundTableColor, setBackgroundTableColor] = useState("")
  useEffect(()=> {
    let url = window.location.href;
    isLexusUrl(url).then(isLexus => {
      if(isLexus) {
        setBackgroundTableColor("bg-lexusBlue")
      } else {
        setBackgroundTableColor("bg-primary")
      }
    })
  }, [])

  return (
    <div>
      <div>
        <Paragraf title={`Ciao ${workOrder?.name} `} />
      </div>
      <div>
        <Paragraf
          title={
            workOrder && ('workOrder' in workOrder ? 'Ordine' : 'Fattura')
          }
          name={`${
            workOrder &&
            ('invoice' in workOrder && 'workOrder' in workOrder
              ? `${workOrder.workOrder}`
              : 'invoice' in workOrder
              ? workOrder.invoice
              : workOrder?.workOrder)
          } - ${moment(workOrder?.date).format('DD-MM-YYYY')} `}
          // cssProps="text-xl text-primary font-ToyotaTypeRegular"
        />
        <Paragraf
          title="Totale"
          name={`${numbersUtil.format(workOrder?.totalAmount)} € `}
          cssProps={`text-xl text-third font-${urlisLexus[0]}`}
        />
      </div>
      <div className= {classNames ("mx-0 p-5 mt-5", backgroundTableColor)}>
        <div className="mt-5 flex justify-between">
          <span className={`text-white font-${urlisLexus[0]} w-1/4 text-left `}>
            Rata
          </span>
          <span className={`text-white font-${urlisLexus[0]} w-2/4 text-left `}>
            Data pagamento
          </span>
          <span className={`text-white font-${urlisLexus[0]} w-2/4 text-left `}>
            Esito pagamento
          </span>
        </div>
        {splittedPayment?.installment?.map((division, index) => {
          return (
            <div key={index} className="mt-5 flex justify-between">
              <span className={"text-white font-"+urlisLexus[0]+" w-1/4 text-left"}>
                {numbersUtil.format(division?.amount?.value)} €
              </span>
              <span className={`text-white font-${urlisLexus[0]} w-2/4 text-left `}>
                {workOrderStatus === 'completed' && index === 0 ? (
                  'Pagata'
                ) : (
                  <>
                    {moment(division.date).format('DD/MM/YYYY') ===
                    moment().format('DD/MM/YYYY')
                      ? 'Oggi'
                      : moment(division.date).format('DD/MM/YYYY')}
                  </>
                )}
              </span>
              <span className={`uppercase text-white w-2/4 text-left font-${urlisLexus[0]}`}>
                da pagare
              </span>
            </div>
          )
        })}
      </div>
      <div className="mt-8">
        <CommonButton
          onClick={onPayHandler}
          type="button"
          loadingApi={loadingApi}
          name="Paga"
        />
        <CommonButton
          cssType='fill-in-white'
          onClick={() => navigate(-1)}
          //type="button"
          name="Indietro"
        />
      </div>

      {workOrderStatus === 'completed' && (
        <div className="mt-5">
          <CommonButton
            cssProps="bg-secondary border-2 border-primary rounded-full py-2 px-5 w-64 text-base text-third"
            onClick={handleNewOperation}
            type="button"
            name="Nuova operazione"
          />
        </div>
      )}
    </div>
  )
}

export default SplitPayments

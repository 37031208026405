/*
If you don't remember how use this class :
https://stackoverflow.com/questions/32888728/correct-way-to-share-functions-between-components-in-react
*/
import { loadConstants } from 'api/api'
import logoToyota from '../images/logo-toyota.svg'
import logoLexus from '../images/LFS logo horizontal 2D Black.jpg'
import { LEXUS_DOMAIN } from '../utils/api';

/**
 * 
 *  take current url and analize it: if it contains Lexus domain then show lexus layout
 */
export const isLexus = (type, props) => {
    var url = window.location.href;
    var response
    
    switch(type) {

        case 'cssTypeHandler':
            response = cssTypeHandler(props, url)
            break
            

        case 'logoHandler':
            logoHandler(props, url)
            break

    }

    return response
    
    
};

export async function isLexusToyotaNavigate(workorder) {
    const constants = (await loadConstants())
    if(workorder.includes("LCU")){
        return constants.LEXUS_DOMAIN
    } 
    return constants.TOYOTA_DOMAIN
};

export async function isLexusCss(url) {
    const constants = (await loadConstants())
    if(url.includes(constants.LEXUS_DOMAIN)){
        return ["LexusTypeRegular","LexusTypeLight"]
    } 
    return ["ToyotaTypeRegular","ToyotaTypeLight"]
};

function cssTypeHandler(cssType, url) {
    cssType = cssType.split(";")[0]
    var css = cssType.split(";")[1]
    var prop
    debugger
    if(url.includes("lexusDomain")) {
        
        if(cssType == "fill-in-white") {
            prop = "bg-white text-lexusBlue " + css + " border-lexusBlue"
        }else {
            prop = "bg-lexusBlue text-white " + css + " border-lexusBlue"
        }
    } else {
        if(cssType == "fill-in-white") {
            prop = "bg-white text-third " + css + " border-primary"
    
        }else {
            prop = "bg-primary text-white " + css + " border-primary"
        }
    }
    return prop;
        
};

export async function isLexusUrl(url) {
    const constants = (await loadConstants())
    if(url.includes(constants.LEXUS_DOMAIN)){
        return true
    } 
    return false
};

export async function urlRight(url) {
    const constants = (await loadConstants())
    if(url.includes(constants.TOYOTA_DOMAIN)){
        return constants.TOYOTA_DOMAIN
    } 
    return constants.LEXUS_DOMAIN
};

export async function getUrlToyota() {
    const constants = (await loadConstants())
    return constants.TOYOTA_DOMAIN
};

export async function getUrlLexus() {
    const constants = (await loadConstants())
    return constants.URL_LEXUS
};

export async function getBrandByURL(url) {
    const constants = (await loadConstants())
    if(url.includes(constants.TOYOTA_DOMAIN)){
        return constants.BRAND_TOYOTA;
    } 
    return constants.BRAND_LEXUS;
};

export function isLexusUrlStatic(url){
    if(url.includes(LEXUS_DOMAIN)){
        return true;
    } 
    return false;
}
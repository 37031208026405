import { CommonButton, Paragraf } from 'components'
import Title from 'components/Title'
import { useQuery } from 'Hooks/useQuery'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import {
  CUSTOMER_AREA_HOMEPAGE,
  HOMEPAGE,
  KO_FINANCIAL,
} from 'routes/constants'
import { populateWorkOrder } from 'store/slices/appMiscSlice'

const KoFinancial: React.VFC<{
  errorMap: Map<string, string>
}> = ({ errorMap }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const query = useQuery()

  const handleNewOperation = async () => {
    await dispatch(populateWorkOrder({ workOrder: null, status: null }))
    navigate(`/${HOMEPAGE}`)
  }

  useEffect(() => {
    const errorCode = query.get('errorCode')
    if (errorCode) {
      navigate(`/${KO_FINANCIAL}`, { replace: true })
      toast.error(
        errorMap.has(errorCode)
          ? errorMap.get(errorCode)
          : errorMap.get('default'),
      )
    }
  }, [])

  return (
    <div className="mb-2 text-left w-full px-5 pb-5">
      <Title name="..OPS! qualcosa è andato storto" cssProps="text-2xl" />
      <Paragraf name={errorMap.get('default')} />
      <div className="mt-10 mb-10 space-y-8">
        <CommonButton
          onClick={handleNewOperation}
          type="button"
          name="Effettua nuova operazione"
        />
        <CommonButton
          onClick={() => navigate(`/customer-area/${CUSTOMER_AREA_HOMEPAGE}`)}
          type="button"
          name="Area Cliente"
        />
      </div>
    </div>
  )
}

export default KoFinancial

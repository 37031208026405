import React from 'react'
import { isLexusCss } from 'utils/utils'


  let urlisLexus = []
  let url = window.location.href;
  isLexusCss(url).then(isLexus => {
      urlisLexus=isLexus
  })

type TitleProps = {
  cssProps?: string
  name: string
  secondline?: string
}

const Title: React.FC<TitleProps> = ({ cssProps, name, secondline }) => {
  return (
    <>
      <h1
        className={`text-center text-lg font-sans py-4 text-primary font-${urlisLexus[0]} ${cssProps} `}
      >
        {name}
        <br />
        {secondline}
      </h1>
    </>
  )
}

export default Title

import { EXPIRED_CARD_MESSAGE, INVALID_CARD_MESSAGE } from 'commons/constants'
import { CommonButton, Paragraf } from 'components'
import BottomModal from 'components/BottomModal'
import Card from 'components/Card'
import CardActions from 'components/CardActions'
import LoadingBackdrop from 'components/LoadingBackdrop'
import Title from 'components/Title'
import { useQuery } from 'Hooks/useQuery'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { CUSTOMER_AREA_HOMEPAGE, PAYMENT_METHOD } from 'routes/constants'
import {
  addNewUserCard,
  requestActivateUserCard,
  requestCancelUserCard,
  requestSuspendUserCard,
  requestUserCards,
} from 'store/actions'
import {
  Card as CardType,
  selectUserCards,
  selectUserMiscLoading,
} from 'store/slices/userSlice'
import { printToast } from 'utils/printToast'
import { isLexusCss } from 'utils/utils'


  let urlisLexus = []
  let url = window.location.href;
  isLexusCss(url).then(isLexus => {
      urlisLexus=isLexus
  })

const infoCardTextMap = new Map([
  ['singular', 'Carta registrata'],
  ['plural', 'Carte registrate'],
])

const PaymentMethod: React.VFC = () => {
  const dispatch = useDispatch()
  const query = useQuery()
  const navigate = useNavigate()
  const cards = useSelector(selectUserCards)
  const loading = useSelector(selectUserMiscLoading)

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [activeCard, setCardActive] = useState<CardType>(null)

  useEffect(() => {
    dispatch(requestUserCards())
  }, [])

  useEffect(() => {
    const success = JSON.parse(query.get('success'))
    if (success !== null) {
      if (success) {
        printToast.success('Carta aggiunta con successo!')
      } else if (query.get('errorCode') === 'INVALID_CARD') {
        printToast.error(INVALID_CARD_MESSAGE)
      } else if (query.get('errorCode') === 'EXPIRED_CARD') {
        printToast.error(EXPIRED_CARD_MESSAGE)
      } else {
        printToast.error(
          'Non è stato possibile aggiungere una nuova carta di credito. Riprovare più tardi. ',
        )
      }

      navigate(`/customer-area/${PAYMENT_METHOD}`, { replace: true })
    }
  }, [query])

  const openModal = () => setIsModalOpen(true)
  const closeModal = () => setIsModalOpen(false)
  const handleOnDismissModal = () => setIsModalOpen(false)

  const handleActivateCard = (cardId: number) => {
    closeModal()
    dispatch(requestActivateUserCard({ cardId }))
  }
  const handleSuspendCard = (cardId: number) => {
    closeModal()
    if (cards.filter(card => card.status == 'ACTIVE').length > 1) {
      dispatch(requestSuspendUserCard({ cardId }))
    } else {
      printToast.error('Non è possibile disattivare tutte le carte.', 5000)
    }
  }
  const handleCancelCard = (cardId: number) => {
    closeModal()
    dispatch(requestCancelUserCard({ cardId }))
  }

  const handleAddNewCard = () => {
    dispatch(addNewUserCard())
  }
  const handleCardClick = (card: CardType) => {
    if (cards.length > 1 && cards.some(card => card.status === 'ACTIVE')) {
      setCardActive(card)
      openModal()
    }
  }

  return (
    <div className="mb-2 text-left w-full px-5 pb-5">
      <Title name="Metodi di pagamento" cssProps="text-2xl" />
      <div className="mt-5 mb-10">
        <Paragraf
          cssProps={`text-left font-${urlisLexus[0]} `}
          name={infoCardTextMap.get(cards.length > 1 ? 'plural' : 'singular')}
        />
        {loading && <LoadingBackdrop />}
        {cards &&
          cards.map(card => (
            <div key={card.cardId}>
              <Card card={card} onCardClick={() => handleCardClick(card)} />
              <hr />
            </div>
          ))}
        <Paragraf
          cssProps={`text-center my-10 font-${urlisLexus[0]} `}
          name="oppure"
        />
        <CommonButton
          onClick={handleAddNewCard}
          type="button"
          name="Aggiungi carta"
        />
        <CommonButton
          onClick={() => {
            navigate(`/customer-area/${CUSTOMER_AREA_HOMEPAGE}`)
          }}
          type="button"
          name="Torna indietro"
          cssType='fill-in-white'
        />
      </div>
      <BottomModal isOpen={isModalOpen} onDismiss={handleOnDismissModal}>
        <div className="text-center space-y-5 py-3">
          <Title name="Azioni carta" />

          <CardActions
            card={activeCard}
            onSuspendCard={() => {
              handleSuspendCard(activeCard.cardId)
            }}
            onCancelCard={() => {
              handleCancelCard(activeCard.cardId)
            }}
            onActivateCard={() => {
              handleActivateCard(activeCard.cardId)
            }}
          />

          <CommonButton
            name="Annulla"
            cssType='fill-in-white'
            onClick={closeModal}
          />
        </div>
      </BottomModal>
    </div>
  )
}

export default PaymentMethod

import { AxiosResponse } from 'axios'
import { easyPayInstance } from 'Axios/AxiosInstance'
import {
  SubscribedDocumentDetail,
  SubscribedDocuments,
} from 'commons/dto/legalDoc'
import { isLexusUrlStatic } from 'utils/utils';

let appId = "TOYOTA"
let url = window.location.href;
let brand = 'TOYOTA'
if(isLexusUrlStatic(url)){
    brand = 'LEXUS'
}

export const subscribeDocuments = (documentId: string, userId: string) =>
  easyPayInstance.post(`/${appId}/informative-documents/subscription`, {
    documents: [
      {
        documentId,
        subscribed: true,
      },
    ],
    userId,
  })

export const getSubscribedDocuments = (): Promise<
  AxiosResponse<SubscribedDocuments>
> => easyPayInstance.get(`/${appId}/informative-documents/subscription`)

export const downloadSubscribedDocument = ({
  userId,
  documentId,
}: {
  userId: string
  documentId: string
}): Promise<AxiosResponse<SubscribedDocumentDetail>> =>
  easyPayInstance.get(
    `/${appId}/informative-documents-subscription/?documentId=${documentId}`,
  )

import AlertGeneral from 'components/AlertGeneral/AlertGeneral'
import BottomModal from 'components/BottomModal'
import CommonButton from 'components/CommonButton'
import FAB from 'components/FAB'
import Paragraf from 'components/Paragraf'
import Stepper from 'components/Stepper'
import Title from 'components/Title'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { requestOneShotPayment } from 'store/actions'
import { selectWorkOrder } from 'store/slices/appMiscSlice'
import { LocalStorageService } from 'utils/LocalStorageService'
import numbersUtil from 'utils/numberFormatUtils'
import { classNames } from 'utils/css'
import { isLexusCss,isLexusUrl } from 'utils/utils'

let urlisLexus = []
let url = window.location.href;
isLexusCss(url).then(isLexus => {
    urlisLexus=isLexus
})

const STEPS = ['Dettaglio ordine', 'checkout', 'fine transazione']
const CURRENT_STEP = 1
const PaymentDirect: React.FC = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const workOrder = useSelector(selectWorkOrder)
  const [errorApi, setErrorApi] = useState(false)
  const [isDisabled, setIsDisabled] = useState(false)

  const [isStepModalOpen, setIsStepModalOpen] = useState(false)
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false)
  let backgroundWarningBar="bg-primary"


  let url = window.location.href;
  isLexusUrl(url).then(isLexus => {
  if(isLexus) {
    backgroundWarningBar="bg-lexusBlue"
  } else {
    backgroundWarningBar="bg-primary"
  }
  })

  const [backgroundAlertColor, setbackgroundAlertColor] = useState("")
  useEffect(() => {
    let url = window.location.href;
    isLexusUrl(url).then(isLexus => {
    if(isLexus) {
      setbackgroundAlertColor("bg-lexusBlue")
    }else{
      setbackgroundAlertColor("bg-primary")
    }})
  },[])

  useEffect(() => {
    LocalStorageService.addObject({ paymentType: 'direct' })
    if (Object.entries(workOrder).length === 0) {
      navigate('/')
    }
  }, [workOrder, isDisabled, navigate])

  function onPayHandler() {
    setIsDisabled(true)
    dispatch(
      requestOneShotPayment({
        description: `pagamento ordine ${workOrder.code} - ${moment(
          workOrder.date,
        ).format('DD-MM-YYYY')}`,
        workOrderId: workOrder.code,
        dealerCode: workOrder.dealerCode,
      }),
    )
  }

  const handleOpenConfirmModal = () => {
    setIsConfirmModalOpen(true)
  }
  const handleOpenStepper = () => setIsStepModalOpen(true)
  const handleOnDismissStepModal = () => setIsStepModalOpen(false)
  const handleOnDismissConfirmModal = () => setIsConfirmModalOpen(false)

  return (
    <>
      {errorApi && (
        <AlertGeneral
          color="danger"
          setTrigger={setErrorApi}
          description="Errore per favore riprovare"
        />
      )}
      <div className="mb-2 text-center w-full px-5 pb-5">
        <div className="mt-8">
          <>
            <div className="mt-8">
              <Paragraf title={`Ciao ${workOrder.name} `} />
              <Paragraf
                title={'workOrder' in workOrder ? 'Ordine' : 'Fattura'}
                name={`${
                  'workOrder' in workOrder
                    ? workOrder?.workOrder
                    : workOrder.invoice
                } - ${moment(workOrder.date).format('DD-MM-YYYY')} `}
              />
              <Paragraf
                title="Totale"
                name={`${numbersUtil.format(workOrder?.totalAmount)} € `}
                cssProps={`text-xl text-third font-${urlisLexus[0]} `}
              />
            </div>
            <div className="mx-5 mt-10">
              <CommonButton name="Paga" onClick={handleOpenConfirmModal} />
              <CommonButton
                cssType='fill-in-white'
                onClick={() => navigate(-1)}
                type="button"
                name="Indietro"
              />
            </div>
          </>
        </div>
      </div>
      <FAB onClick={handleOpenStepper} />
      <BottomModal
        isOpen={isStepModalOpen}
        onDismiss={handleOnDismissStepModal}
      >
        <Stepper stepsLabels={STEPS} currentStep={CURRENT_STEP} />
      </BottomModal>
      <BottomModal
        isOpen={isConfirmModalOpen}
        onDismiss={handleOnDismissConfirmModal}
      >
        <div className="text-center space-y-5 py-3">
          <Title name="Avviso" />
          <div
            className={classNames("flex items-center text-white text-sm font-bold px-4 py-3", backgroundAlertColor)}
            role="alert"
          >
            <svg
              className="fill-current w-4 h-4 mr-2"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path d="M12.432 0c1.34 0 2.01.912 2.01 1.957 0 1.305-1.164 2.512-2.679 2.512-1.269 0-2.009-.75-1.974-1.99C9.789 1.436 10.67 0 12.432 0zM8.309 20c-1.058 0-1.833-.652-1.093-3.524l1.214-5.092c.211-.814.246-1.141 0-1.141-.317 0-1.689.562-2.502 1.117l-.528-.88c2.572-2.186 5.531-3.467 6.801-3.467 1.057 0 1.233 1.273.705 3.23l-1.391 5.352c-.246.945-.141 1.271.106 1.271.317 0 1.357-.392 2.379-1.207l.6.814C12.098 19.02 9.365 20 8.309 20z" />
            </svg>
            <p className={classNames(backgroundAlertColor)}>
              Procedendo verrai reindirizzato al servizio di pagamento, in cui
              potrai eseguire il checkout e completare l&apos;ordine
            </p>
          </div>
          <CommonButton
            name="Continua"
            disabled={isDisabled}
            onClick={onPayHandler}
          />
        </div>
      </BottomModal>
    </>
  )
}

export default PaymentDirect

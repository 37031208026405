import { yupResolver } from '@hookform/resolvers/yup'
import React from 'react'
import { useForm, UseFormProps } from 'react-hook-form'
import PhoneInput from 'react-phone-number-input'
import it from 'react-phone-number-input/locale/it.json'
import 'react-phone-number-input/style.css'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { decrementCurrentStepBy, selectStepAmount, setCurrentStep } from 'store/slices/registrationStepper'
import * as Yup from 'yup'
import CommonButton from '../CommonButton'
import LabelTailwind from '../Label'
import Paragraf from '../Paragraf'
import { isLexusCss } from 'utils/utils'

let urlisLexus = []
let url = window.location.href;
isLexusCss(url).then(isLexus => {
  urlisLexus=isLexus
})

type Props = {
  handleConfirmPhoneSubmit: ({ phone }: { phone: string }) => void
  initialPhoneNumber: string
  isUpdatingPhone?: boolean
  totalStep: number
}

const phoneRegExp = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im

const FormConfirmPhone: React.FC<Props> = ({
  totalStep = useSelector(selectStepAmount),
  handleConfirmPhoneSubmit,
  initialPhoneNumber,
  isUpdatingPhone = false,
}) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const goBack = () => {
    if(totalStep == 6){
      dispatch(setCurrentStep(1));
    } else {
      dispatch("/registration");
    }
    navigate(-1)
  }

  const validationSchema = Yup.object().shape({
    phone: Yup.string().matches(phoneRegExp, 'numero non valido'),
  })

  const formOptions: UseFormProps = {
    resolver: yupResolver(validationSchema),
    mode: 'onBlur',
    defaultValues: {
      phone: (initialPhoneNumber ?? '')?.replaceAll(' ', ''),
    },
  }
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    getValues,
    trigger,
  } = useForm(formOptions)

  const handleOnInputBlur = () => {
    setValue('phone', getValues('phone'))
    trigger(['phone'])
  }

  const isUpdatingPhoneLabels = {
    headerTitle: 'Scegli il nuovo numero di telefono',
  }

  const isNotUpdatingPhoneLabels = {
    headerTitle: 'Inserisci il tuo numero di telefono',
  }

  return (
    <div className="mb-2 text-left w-full px-5 pb-5">
      <form onSubmit={handleSubmit(handleConfirmPhoneSubmit)} noValidate>
        <Paragraf
          cssProps={"mt-4 text-center font-"+urlisLexus[0]}
          name="Scegli il prefisso e inserisci il tuo numero di telefono"
        />
        <div className="w-full pl-2 pr-2 mt-5 space-y-8">
          <div>
            <PhoneInput
              className="px-2 py-4"
              name="phone"
              {...register('phone')}
              value={(initialPhoneNumber ?? '')?.replaceAll(' ', '')}
              onChange={value => setValue('phone', value)}
              onBlur={() => handleOnInputBlur()}
              defaultCountry="IT"
              initialValueFormat="national"
              labels={it}
              placeholder="Telefono"
            />

            {errors && (
              <div className="mb-2">
                <LabelTailwind
                  name={errors?.phone?.message}
                  cssProps="text-third font-ToyotaTypeRegular"
                />
              </div>
            )}
          </div>
        </div>
        <div className="fixed bottom-16 left-0 w-full space-y-8">
          <CommonButton type="submit" name="Continua" disabled={!isValid} />
          <CommonButton
            onClick={goBack}
            type="button"
            name="Torna indietro"
            cssType='fill-in-white'
          />
        </div>
      </form>
    </div>
  )
}

export default FormConfirmPhone

import { createAction } from '@reduxjs/toolkit'

export const requestOneShotPayment = createAction(
  'payment/oneShot/request',
  function prepare({
    description,
    workOrderId,
    dealerCode,
  }: {
    description: string
    workOrderId: string
    dealerCode: string
  }) {
    return {
      payload: {
        description,
        workOrderId,
        dealerCode,
      },
    }
  },
)

export const requestUserCards = createAction('/userCards/request')
export const requestActivateUserCard = createAction(
  '/userCards/activate/request',
  function prepare({ cardId }: { cardId: number }) {
    return {
      payload: {
        cardId,
      },
    }
  },
)
export const requestSuspendUserCard = createAction(
  '/userCards/suspend/request',
  function prepare({ cardId }: { cardId: number }) {
    return {
      payload: {
        cardId,
      },
    }
  },
)

export const requestCancelUserCard = createAction(
  '/userCards/cancel/request',
  function prepare({ cardId }: { cardId: number }) {
    return {
      payload: {
        cardId,
      },
    }
  },
)

export const addNewUserCard = createAction('/userCards/add/request')
export const requestCreateSubscription = createAction(
  'subscription/request',
  function prepare({
    workOrderId,
    dealerCode,
  }: {
    workOrderId: string
    dealerCode: string
  }) {
    return {
      payload: {
        workOrderId,
        dealerCode,
      },
    }
  },
)
export const requestActivateSubscription = createAction(
  'subscription/activate/request',
)

export const requestUserActiveWorkOrders = createAction(
  'request/activeWorkOrders',
  function prepare({ from, to }: { from?: string; to?: string }) {
    return {
      payload: {
        from,
        to,
      },
    }
  },
)

export const requestPayInstalment = createAction(
  'payment/installment/request',
  function prepare({
    workOrderId,
    installmentId,
    paymentMethodId,
    dealerCode,
  }) {
    return {
      payload: {
        workOrderId,
        installmentId,
        paymentMethodId,
        dealerCode,
      },
    }
  },
)

export const requestInformativeDocuments = createAction(
  'request/informativeDocuments',
)
export const resetState = createAction('__resetState')
export const offline = createAction('__rtkq/offline')
export const online = createAction('__rtkq/online')

import { phoneApi } from 'api/easypay'
import FormConfirmPhone from 'components/FormConfirmPhone'
import FormPinPad from 'components/FormPinPad'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toggleLoading } from 'store/slices/appMiscSlice'
import {
  logOutRequest,
  selectUserId,
  selectUserPhone,
  setUserPhone,
} from 'store/slices/userSlice'
import { printToast } from 'utils/printToast'

const PhoneSubmission: React.FC = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [section, setSection] = useState('form')
  const [newPhoneNumber, setNewPhoneNumber] = useState<string>(null)
  const userId = useSelector(selectUserId)
  const { phoneNumber: userPhoneNumber } = useSelector(selectUserPhone)

  const handlePhoneSubmit = async ({ phone }) => {
    dispatch(toggleLoading({ value: true }))
    try {
      await phoneApi.updatePhoneNumber(userPhoneNumber, phone)
      setNewPhoneNumber(phone)
      setSection('confirm-code')
    } catch (e) {
      console.error(e)
      const message = JSON.parse(e?.response?.data?.messages[0]?.message)
      if (message.code === 'USED_PHONE_NUMBER') {
        printToast.error(message.message, 6000)
      } else {
        printToast.error(
          'Qualcosa è andato storto, prova a reinserire il numero di telefono',
          5000,
        )
      }
    } finally {
      dispatch(toggleLoading({ value: false }))
    }
  }

  const onConfirmCode = async ({ confirmCode }) => {
    try {
      dispatch(toggleLoading({ value: true }))
      await phoneApi.confirmPhoneNumber(userId, confirmCode, newPhoneNumber)
      printToast.success('Numero di telefono aggiornato con successo!')
      await dispatch(
        setUserPhone({ phoneNumber: newPhoneNumber, entityId: userId }),
      )
      navigate('/customer-area/homepage')
    } catch (e) {
      console.error(e)
      printToast.error(undefined, undefined, () =>
        dispatch(logOutRequest({ targetPath: '/', navigate })),
      )
    } finally {
      dispatch(toggleLoading({ value: false }))
    }
  }

  const onResendOtp = async () => {
    dispatch(toggleLoading({ value: true }))
    try {
      await phoneApi.resendOtpPhoneNumber(userId, newPhoneNumber)
      printToast.success('Codice di conferma inviato nuovamente')
      console.log(`OTP Resend to ${newPhoneNumber} request sent successful`)
    } catch (e) {
      console.error(e)
      console.warn(`OTP Resend to ${newPhoneNumber} request failed`)
      printToast.error()
    } finally {
      dispatch(toggleLoading({ value: false }))
    }
  }

  const handleFormPinPadGoBack = () => {
    setSection('form')
  }

  const RenderSectionsMap = new Map([
    [
      'form',
      <FormConfirmPhone
        key="form"
        initialPhoneNumber={userPhoneNumber}
        handleConfirmPhoneSubmit={handlePhoneSubmit}
        isUpdatingPhone
      />,
    ],
    [
      'confirm-code',
      <FormPinPad
        key="confirm-code"
        header="Conferma codice"
        subHeader={`Inserisci il codice di conferma che hai ricevuto al numero ${newPhoneNumber} `}
        onPinPadSubmit={onConfirmCode}
        onResendConfirmationCode={onResendOtp}
        onGoBack={handleFormPinPadGoBack}
      />,
    ],
  ])

  return RenderSectionsMap.get(section)
}

export default PhoneSubmission

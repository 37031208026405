import SplitPayments from 'components/SplitPayment'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { LOGIN } from 'routes/constants'
import { requestCreateSubscription } from 'store/actions'
import { populateWorkOrder, selectWorkOrder } from 'store/slices/appMiscSlice'
import {
  selectCurrentSubscription,
  selectUserMiscLoading,
} from 'store/slices/userSlice'
import { LocalStorageService } from 'utils/LocalStorageService'

const SplitPayment: React.FC = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation()
  const workOrder = useSelector(selectWorkOrder)
  const loadingApi = useSelector(selectUserMiscLoading)
  const splittedPayment = useSelector(selectCurrentSubscription)
  const title = {
    form: 'Dati anagrafici',
    'payment-recap': 'Pagamento a rate',
  }

  const subtitle = {
    form: "Questi dati verrano utilizzati per verificare la tua identita'",
  }

  const sendFormData = React.useCallback(() => {
    if (workOrder && Object.entries(workOrder).length !== 0) {
      dispatch(
        requestCreateSubscription({
          workOrderId: workOrder.code,
          dealerCode: workOrder.dealerCode,
        }),
      )
    }
  }, [workOrder])

  React.useEffect(() => {
    if (workOrder && Object.entries(workOrder).length === 0) {
      dispatch(
        populateWorkOrder({
          workOrder: LocalStorageService.getObject().workOrder,
          status: 'uncompleted',
        }),
      )
    }
    LocalStorageService.addObject({ paymentType: 'split' })
    if (location.pathname.includes('split-payment-overview')) {
      sendFormData()
    }
  }, [location, sendFormData, workOrder, dispatch])
  const onPayHandler = () => {
    navigate(`/${LOGIN}?redirectReason=split-payment`, { replace: true })
  }

  return (
    <div className="mb-2 text-left w-full px-5 pb-5">
      <div className="mt-5 mb-10">
        <div className="text-center">
          <SplitPayments
            splittedPayment={splittedPayment}
            workOrder={workOrder}
            workOrderStatus={workOrder ? workOrder.status : 'uncompleted'}
            loadingApi={loadingApi}
            onPayHandler={onPayHandler}
            isOverview={location.pathname.includes('split-payment-overview')}
          />
        </div>
      </div>
    </div>
  )
}

export default SplitPayment

import { workOrdersApi } from 'api/easypay'
import CommonButton from 'components/CommonButton'
import Paragraf from 'components/Paragraf'
import { useQuery } from 'Hooks/useQuery'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { populateWorkOrder } from 'store/slices/appMiscSlice'
import { setStepAmount } from 'store/slices/registrationStepper'
import { logOutRequest } from 'store/slices/userSlice'
import { LocalStorageService } from 'utils/LocalStorageService'
import numbersUtil from 'utils/numberFormatUtils'
import { printToast } from 'utils/printToast'

const RecapPage: React.FC = () => {
  let workOrder = LocalStorageService.getObject()?.workOrder
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const query = useQuery()
  const [refreshPage, setRefreshPage] = useState(false)

  

  useEffect(() => {
    if (window.location.href.includes('?workOrderId=')) {
      findWorkOrderById(
        decodeURIComponent(window.location.href.split('?workOrderId=')[1]),
      )
      if(workOrder && workOrder.status === "EXPIRED") {
        printToast.error('Il link è scaduto',5000,)
      } else if(workOrder && workOrder.status === "PAID") {
        printToast.error("L'ordine di lavoro è già stato pagato",5000,)
      } else if(workOrder && workOrder.status === "IN_PROGRESS") {
        printToast.error("L'ordine di lavoro è in corso di pagamento",5000,)
      }
    }
  }, [refreshPage])


  useEffect(() => {
    workOrder = LocalStorageService.getObject()?.workOrder
    let workOrderCheck = workOrder?.uploadedByDealer ? workOrder.uploadedByDealer : false;
    console.log("workordercheck: "+workOrderCheck)
    dispatch(setStepAmount(workOrderCheck === true ? 4 : 6))
  }, [dispatch, query, setStepAmount])

  async function findWorkOrderById(workOrderId) {
    console.log('findWorkOrderById'+workOrderId)
    workOrdersApi
      .getWorkOrderById({
        workOrderId: workOrderId,
      })
      .then(response => {
        console.log(response+"response recived")
        dispatch(
          populateWorkOrder({
            workOrder: response.data[0],
            status: 'uncompleted',
          }),
        )

        LocalStorageService.addObject({ workOrder: response.data[0] })
        workOrder = LocalStorageService.getObject()?.workOrder

        let workOrderCheck = workOrder?.uploadedByDealer ? workOrder.uploadedByDealer : false;
        dispatch(setStepAmount(workOrderCheck === true ? 4 : 6))
        setRefreshPage(true)
        
        return workOrder
      })
      .catch(error => {
        console.error(error)
      })
  }

  const goBack = () => {
    dispatch(logOutRequest({ targetPath: '/', navigate }))
  }


  if (!workOrder || !Object.entries(workOrder).length) {
    goBack()
    return null
  }

  return (
    <>
      {workOrder && workOrder.status == 'PENDING' &&
      <div className="mb-2 text-left w-full px-5 pb-5">
        <h2>
          Si prega di verificare la correttezza dei dati. In caso di errori
          rieseguire l&apos;acquisizione del documento
        </h2>
        <br />
        <Paragraf title="Intestatario" name={workOrder.name} />
        <Paragraf title="Concessionaria" name={workOrder.dealerName} />
        <Paragraf title="Targa" name={workOrder.plate} />
        <Paragraf
          title={'workOrder' in workOrder ? 'Ordine' : 'Fattura'}
          name={`${
            'invoice' in workOrder && 'workOrder' in workOrder
              ? `${workOrder.workOrder}`
              : 'invoice' in workOrder
              ? workOrder.invoice
              : workOrder?.workOrder
          } - ${moment(workOrder.date).format('DD-MM-YYYY')} `}
        />

        <Paragraf
          cssProps="pb-4 font-weight-bold text-third"
          title="Prezzo"
          name={numbersUtil.format(workOrder.totalAmount) + ' €'}
        />
        <Paragraf title="Modello" name={workOrder.carModel} />

        <div className="mt-8 text-center">
          <CommonButton
            type="button"
            name="Vai al pagamento"
            onClick={() => navigate('/choose-option')}
          />
        </div>
        <div className="mt-5 text-center">
          <CommonButton
            type="button"
            name="Indietro"
            //cssProps="bg-secondary border-2 border-primary rounded-full py-2 px-5 w-64 text-base text-third mx-auto block"
            onClick={goBack}
            cssType='fill-in-white'
          />
        </div>
      </div>
    }
    </>
  )
}

export default RecapPage

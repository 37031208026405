import { ReactText } from 'react'
import { toast } from 'react-toastify'

const defaultSuccessMessage = 'Pagamento approvato con successo.'
const defaultErrorMessage = "E' avvenuto un errore. Riprovare più tardi."

export const printToast = {
  success: (
    successText?: string,
    autoClose = 60000,
    callback?: VoidFunction,
    id?: string,
  ): ReactText =>
    toast.success(successText || defaultSuccessMessage, {
      position: 'top-right',
      autoClose,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      onClose: callback,
      ...(id && { toastId: id }),
    }),
  error: (
    errorText?: string,
    autoClose = 60000,
    callback?: VoidFunction,
    id?: string,
  ): ReactText =>
    toast.error(errorText || defaultErrorMessage, {
      position: 'top-right',
      autoClose,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      onClose: callback,
      ...(id && { toastId: id }),
    }),
}

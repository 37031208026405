import { CommonButton, Paragraf } from 'components'
import LoadingBackdrop from 'components/LoadingBackdrop'
import Title from 'components/Title'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { CUSTOMER_AREA_PAYMENTS_MADE } from 'routes/constants'
import { requestUserActiveWorkOrders } from 'store/actions'
import {
  selectPaidInstalments,
  selectUserMiscLoading,
} from 'store/slices/userSlice'

import { isLexusCss } from 'utils/utils'


  let urlisLexus = []
  let url = window.location.href;
  isLexusCss(url).then(isLexus => {
      urlisLexus=isLexus
  })

const PaymentsMadeDetail: React.VFC = () => {
  const { workOrderId, instalmentId } = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const loading = useSelector(selectUserMiscLoading)
  const workOrders = useSelector(selectPaidInstalments)
  const [selectedWorkOrder, setSelectedWorkOrder] = useState(null)
  const [selectedInstalment, setSelectedInstalment] = useState(null)

  useEffect(() => {
    dispatch(requestUserActiveWorkOrders({}))
  }, [])

  useEffect(() => {
    if (workOrders.length > 0) {
      const activeWorkOrder = workOrders.find(
        workOrder => workOrder.workOrderId === workOrderId,
      )
      setSelectedWorkOrder(activeWorkOrder)
    }
  }, [workOrders, workOrderId])

  useEffect(() => {
    if (selectedWorkOrder !== null) {
      const activeInstalment = selectedWorkOrder?.detail?.installment.find(
        instalment => instalment.installmentId === instalmentId,
      )

      setSelectedInstalment(activeInstalment)
    }
  }, [selectedWorkOrder, instalmentId])

  return (
    <div className="mb-2 text-left w-full px-5 pb-5">
      <Title name="Dettaglio pagamento" cssProps="text-2xl" />
      <div className="mt-5 mb-10">
        <Paragraf
          cssProps={`text-center font-${urlisLexus[0]} `}
          name={`Dettaglio pagamento in riferimento all'ordine di lavoro ${workOrderId}  `}
        />
        {loading && <LoadingBackdrop />}
        {workOrders.length === 0 && !loading && (
          <Paragraf
            cssProps={`text-center font-${urlisLexus[0]} `}
            name="Nessun pagamento trovato, si prega di provare a effettuare una ricerca con filtri diversi"
          />
        )}
        {selectedWorkOrder !== null && selectedInstalment !== null && (
          <div className="my-4">
            <h2 className="text-lg mb-2">
              Pagamento rata n.{Number(selectedInstalment?.installmentId) + 1}
              <span className="text-sm text-red-500 font-mono bg-red-100 inline rounded-full px-2 align-top float-right">
                -
                {new Intl.NumberFormat('it-IT', {
                  style: 'currency',
                  currency: 'EUR',
                }).format(selectedInstalment?.amount?.value)}
              </span>
            </h2>
            <div className="flex justify-between flex-wrap">
              <p className="font-bold my-2 font-light font-mono text-sm text-gray-300 hover:text-gray-900 transition-all duration-200">
                data di emissione:
              </p>
              <span className="font-normal text-sm font-mono inline px-2 align-top ">
                {moment(selectedInstalment?.date).format('DD-MM-YYYY')}
              </span>
            </div>

            <p className="font-bold my-2 font-light font-mono text-sm text-gray-300 hover:text-gray-900 transition-all duration-200">
              Concessionaria:
              <span className="font-normal text-sm font-mono inline px-2 align-top  break-normal">
                {selectedWorkOrder?.dealerAddress}
              </span>
            </p>
            <p className="font-bold my-2 font-light font-mono text-sm text-gray-300 hover:text-gray-900 transition-all duration-200">
              Targa veicolo:
              <span className="font-normal text-sm font-mono inline px-2 align-top float-right">
                {selectedWorkOrder?.plate}
              </span>
            </p>
            <p className="font-bold my-2 font-light font-mono text-sm text-gray-300 hover:text-gray-900 transition-all duration-200">
              Modello veicolo:
              <span className="font-normal text-sm font-mono inline px-2 align-top float-right">
                {selectedWorkOrder?.carModel}
              </span>
            </p>
          </div>
        )}
        <CommonButton
          onClick={() => {
            navigate(`/customer-area/${CUSTOMER_AREA_PAYMENTS_MADE}`)
          }}
          type="button"
          name="Torna indietro"
          cssProps="mt-4 bg-secondary border-2 border-primary rounded-full py-2 px-5 w-64 text-base text-third mx-auto block"
        />
      </div>
    </div>
  )
}

export default PaymentsMadeDetail

import { yupResolver } from '@hookform/resolvers/yup'
import React from 'react'
import { useForm, ValidationMode } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  decrementCurrentStep,
  selectCurrentStep,
  toggleStepVisibility,
} from 'store/slices/registrationStepper'
import * as Yup from 'yup'
import CommonButton from '../CommonButton'
import Input from '../Input'
import Paragraf from '../Paragraf'

type FormConfirmEmailProps = {
  workOrderEmail: string
  handleConfirmEmailSubmit: (data: { email: string }) => void
  isUpdatingEmail?: boolean
}

const FormConfirmEmail: React.FC<FormConfirmEmailProps> = ({
  workOrderEmail,
  handleConfirmEmailSubmit,
  isUpdatingEmail = false,
}) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const goBack = async () => {
    await dispatch(decrementCurrentStep())
    navigate('/registration')
  }
  const validationSchema = Yup.object().shape({
    email: Yup.string().required('Campo richiesto').email(),
    confirmEmail: Yup.string()
      .required('Campo richiesto')
      .oneOf([Yup.ref('email'), null], 'Le email devono combaciare'),
  })

  const formOptions = {
    resolver: yupResolver(validationSchema),
    mode: 'all' as keyof ValidationMode,
    defaultValues: {
      email: workOrderEmail ?? '',
      confirmEmail: workOrderEmail ?? ' ',
    },
  }
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm(formOptions)

  const onSubmit = data => {
    handleConfirmEmailSubmit(data)
  }

  const isUpdatingEmailLabels = {
    headerTitle: 'Aggiorna la tua Mail',
    emailLabel: 'Nuova Email',
    confirmEmailLabel: 'Conferma nuova Email',
  }

  const isNotUpdatingEmailLabels = {
    headerTitle: 'Inserisci la tua mail',
    emailLabel: 'Email',
    confirmEmailLabel: 'Conferma Email',
  }

  return (
    <div className="mb-2 text-left w-full px-5 pb-5">
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Paragraf
          cssProps="mt-4 text-center"
          name="Inserisci il tuo indirizzo email"
        />
        <div id="username" className="w-full pl-2 pr-2 mt-5 space-y-8">
          <div>
            <Input
              cssProps="border-2 rounded w-full h-12 px-2"
              type="email"
              autoComplete="email"
              register={register}
              name="email"
              error={errors?.email}
              customLabel={
                isUpdatingEmail
                  ? isUpdatingEmailLabels.emailLabel
                  : isNotUpdatingEmailLabels.emailLabel
              }
            />
          </div>
          <div className="mt-4">
            <Input
              cssProps="border-2 rounded w-full h-12 px-2"
              type="email"
              autoComplete="email"
              register={register}
              name="confirmEmail"
              error={errors?.confirmEmail}
              customLabel={
                isUpdatingEmail
                  ? isUpdatingEmailLabels.confirmEmailLabel
                  : isNotUpdatingEmailLabels.confirmEmailLabel
              }
            />
          </div>
        </div>
        <div className="mb-2 mt-16 text-center w-full space-y-8">
          <CommonButton type="submit" name="Continua" disabled={!isValid} />
          <CommonButton
            onClick={goBack}
            type="button"
            name="Torna indietro"
            cssType='fill-in-white'
          />
        </div>
      </form>
    </div>
  )
}

export default FormConfirmEmail

import React from 'react'
import { IoHelp } from 'react-icons/io5'
import { FiFilter } from 'react-icons/fi'
type FabProps = {
  onClick: () => void
  icon?: string
}

const FabIconMap = new Map([
  ['IoHelp', <IoHelp key="IoHelp" size="2em" color="white" />],
  ['FiFilter', <FiFilter key="FiFIlter" size="2em" color="white" />],
])

const FAB: React.VFC<FabProps> = ({ onClick, icon = 'IoHelp' }) => {
  return (
    <button
      onClick={onClick}
      className="fixed right-4 bottom-4 flex justify-center items-center p-0 w-12 h-12 bg-blue-900 rounded-full hover:bg-primary active:shadow-lg mouse shadow transition ease-in duration-200 focus:outline-none"
    >
      {FabIconMap.get(icon) ?? FabIconMap.get('IoHelp')}
    </button>
  )
}

export default FAB

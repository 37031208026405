import { AxiosResponse } from 'axios'
import { UserResponse } from 'commons/dto/user'
import { easyPayInstance } from 'Axios/AxiosInstance'

let appId = "TOYOTA"

export const getUser = (): Promise<AxiosResponse<UserResponse>> =>
  easyPayInstance.get(`/${appId}/user/`, {
    params: {},
  })

export const insertUser = (
  userId: string,
  dateOfBirth: string,
  gender: string,
  name: string,
  nationality: string,
  placeOfBirth: string,
  surname: string,
  taxCode: string,
): Promise<AxiosResponse<void>> =>
  easyPayInstance.post(`/${appId}/user/${userId}`, {
    dateOfBirth,
    gender,
    name,
    nationality,
    placeOfBirth,
    surname,
    taxCode,
  })

export const updatePersonalInfo = (
  userId: string,
  dateOfBirth: string,
  gender: 'M' | 'F',
  name: string,
  nationality: string,
  placeOfBirth: string,
  surname: string,
  taxCode: string,
): Promise<AxiosResponse<void>> =>
  easyPayInstance.patch(`/${appId}/user/${userId}`, {
    dateOfBirth,
    gender,
    name,
    nationality,
    placeOfBirth,
    surname,
    taxCode,
  })

import React from 'react'
import logo from '../../images/logo-toyota.svg'
import Title from 'components/Title'
import Paragraf from 'components/Paragraf'
import { useNavigate } from 'react-router-dom'
import { isLexusCss } from 'utils/utils'


  let urlisLexus = []
  let url = window.location.href;
  isLexusCss(url).then(isLexus => {
      urlisLexus=isLexus
  })

const ErrorPage: React.VFC = () => {
  const navigate = useNavigate()
  return (
    <div className="mt-8 mb-8 px-5">
      <div className="fixed top-5">
        <img src={logo} alt="Logo" className="h-12 mr-auto ml-auto" />
      </div>
      <div className="mt-16">
        <Title name="Error operazione" />
      </div>
      <Paragraf name="E' avvenuto un errore. La invitiamo a riprovare piu tardi." />
      <div className="mb-2 mt-8 text-center w-full ">
        <button
          className={`bg-primary rounded-full py-2 px-5 text-white text-base font-${urlisLexus[0]} mx-auto block w-64 `}
          onClick={() => navigate('/')}
        >
          Ricomincia
        </button>
      </div>
    </div>
  )
}

export default ErrorPage

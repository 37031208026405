import { uploadFile } from 'Axios/ApiCall'
import AlertGeneral from 'components/AlertGeneral/AlertGeneral'
import Paragraf from 'components/Paragraf'
import UploadFile from 'components/UploadFile'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { populateWorkOrder, selectWorkOrder } from 'store/slices/appMiscSlice'
import { LocalStorageService } from 'utils/LocalStorageService'
import { printToast } from 'utils/printToast'
import '../../css/global.css'
import { isLexusUrl,getUrlLexus,getUrlToyota,isLexusCss } from 'utils/utils'

let urlisLexus = []
let url = window.location.href;
isLexusCss(url).then(isLexus => {
  urlisLexus=isLexus
})

const UploadFilePage: React.FC = () => {
  const [picture, setPicture] = React.useState<any>({})
  const [uploadingProcess, setUploadingProgress] = React.useState(0)
  const [loadingApi, setLoadingApi] = React.useState(false)
  const [errorApi, setErrorApi] = React.useState(false)
  const [isFromHomePage, setIsFromHomePage] = React.useState(true)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const workOrder = useSelector(selectWorkOrder)
  const [urlLexus, setUrlLexus] = React.useState("")
  const [urlToyota, setUrlToyota] = React.useState("")
  useEffect(() => {
    getUrlLexus().then(isLexus => {
      setUrlLexus(isLexus)
    })
    getUrlToyota().then(isLexus => {
      setUrlToyota(isLexus)
    })
  },[])


  React.useEffect(() => {
    if (Object.keys(picture).length) {
      setIsFromHomePage(false)
      setLoadingApi(true)
      uploadFile(
        picture.file,
        e => setUploadingProgress((e.loaded / e.total) * 100),
        picture.type,
      )
        .then(response => {
          setLoadingApi(false)
          console.log(response)
          setUploadingProgress(0)
          dispatch(
            populateWorkOrder({
              workOrder: response.data,
              status: 'uncompleted',
            }),
          )
          const workOrder = response.data
          redirectToRightAppBrand(workOrder)  
          LocalStorageService.addObject({ workOrder: response.data })
          setPicture({})
        })
        .catch(e => {
          setLoadingApi(false)
          if(e?.response?.data?.messages !== undefined && e?.response?.data?.messages !== null){
            printToast.error(e?.response?.data?.messages[0]?.message, 6000)
          } else if(e?.response?.data !== undefined && e?.response?.data !== null){
            printToast.error(e?.response?.data, 6000)
          } else {
            setErrorApi(true)
          }
         
          setUploadingProgress(0)
          setPicture({})
          'workOrder' in (LocalStorageService.getObject() ?? {}) &&
            LocalStorageService.removeObject('workOrder')
        })
    }
  }, [picture, dispatch])
  

 function redirectToRightAppBrand(workOrder){
  if (workOrder && Object.keys(workOrder).length) {
    const workOrderId = workOrder.code+'/'+workOrder.dealerCode
    if(workOrder.workOrder.includes('TCU')) {
      window.location.replace(` ${urlToyota}recap/?workOrderId=${workOrderId}`)
    }
    if(workOrder.workOrder.includes('LCU')) {
      window.location.replace(` ${urlLexus}recap/?workOrderId=${workOrderId}`)
    }
  }
 }
 


  const [brandName, setBrandName] = useState("")
  useEffect(() => {
    let url = window.location.href;
    isLexusUrl(url).then(isLexus => {
    if(isLexus) {
      setBrandName("Lexus")
    }else{
      setBrandName("Toyota")
    }})
  },[])
  


  
   
    return(
    <>
      {errorApi && (
        <AlertGeneral
          color="danger"
          setTrigger={setErrorApi}
          description="Errore, per favore riprovare"
        />
      )}
     

      <div className="mb-2 text-left w-full px-5 pb-5">
        <h1 className={"font-"+urlisLexus[0]}>
          Benvenuto in {brandName} Easy Pay <br />
        </h1>
        <br />
        <br />
        <h2>Opzioni pagamento</h2>

        <Paragraf name="Carica i documenti del tuo ordine per iniziare il pagamento" />
        <div className="mt-8">
          <UploadFile
            loadingApi={loadingApi}
            setPicture={setPicture}
            uploadingProcess={uploadingProcess}
          />
        </div>
      </div>
    </>
    )}



export default UploadFilePage

import { AxiosResponse } from 'axios'
import { TempUser } from 'commons/dto/tempUser'
import { easyPayInstance } from 'Axios/AxiosInstance'

let appId = "TOYOTA";

export const fetchTempUser = (tempUserId:string,email:string,phone:string,): Promise<AxiosResponse<TempUser>> =>
  easyPayInstance.post(`/${appId}/temp-user/post`,{tempUserId,email,phone,})

export const confirmTempUserId = (
  tempUserId: string,
  brandName: string,
  email:string,
): Promise<AxiosResponse<void>> =>
  easyPayInstance.post(`/${appId}/temp-user/confirm`, {}, { params: { tempUserId, brandName,email } })

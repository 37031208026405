import CommonButton from 'components/CommonButton'
import Paragraf from 'components/Paragraf'
import React, { useEffect } from 'react'
import { BsInfoCircle } from 'react-icons/bs'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  setCurrentStep,
  toggleStepVisibility,
} from 'store/slices/registrationStepper'
import { selectTempUser, setTempUserIdRequest } from 'store/slices/userSlice'
import { isLexusCss } from 'utils/utils'

let urlisLexus = []
let url = window.location.href;
isLexusCss(url).then(isLexus => {
  urlisLexus=isLexus
})

const Registration: React.FC = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { loading } = useSelector(selectTempUser)

  useEffect(() => {
    dispatch(toggleStepVisibility(false))
    dispatch(setCurrentStep(1))
  }, [])

  const generateTemporaryUserId = async () => {
    if(sessionStorage.getItem("userEmailVerified"))
      sessionStorage.removeItem("userEmailVerified");
    await dispatch(setTempUserIdRequest({ navigate }))
  }
  const goBack = () => {
    navigate("/login?redirectReason=split-payment")
  }
  return (
    <div className="mb-2 text-left w-full px-5 pb-5">
      <BsInfoCircle
        className="mx-auto my-4 mb-8 bg-indigo-100 rounded-full"
        size="2.5em"
        color="#4e5d63"
      />
      <Paragraf
        cssProps={"mx-auto text-center mb-24 font-"+urlisLexus[0]}
        name="Il processo di registrazione durerà qualche minuto. Ti verranno richieste alcune informazioni personali tra cui le credenziali per accedere all'area personale."
      />
      <div className="space-y-8">
        <CommonButton
          loadingApi={loading}
          onClick={generateTemporaryUserId}
          type="button"
          name="Inizia"
        />
        <CommonButton
          onClick={goBack}
          type="button"
          name="Torna indietro"
          cssType='fill-in-white'
        />
      </div>
    </div>
  )
}

export default Registration

import { AxiosResponse } from 'axios'
import { easyPayInstance } from 'Axios/AxiosInstance'

let appId = "TOYOTA"

export const createPassword = ({ userId, username, password1, password2, token }) =>
  easyPayInstance.post(`${appId}/security/${userId}/password`, {
    username,
    password1,
    password2,
    token
  })

export const updatePassword = ({
  userId,
  username,
  oldPassword,
  password1,
  password2,
}) =>
  easyPayInstance.patch(`${appId}/security/password/change`, {
    oldPassword,
    password1,
    password2,
    username,
  })

export const recoverPassword = (
  username: string,
): Promise<AxiosResponse<void>> =>
  easyPayInstance.patch(`${appId}/security/password/recover`, {
    username,
  })

  export const socialLogin = (
    appId: string,
    token: string
  ): Promise<AxiosResponse<{token: string}>> =>
    easyPayInstance.post(`${appId}/socialLogin?appId=${appId}`, {
      token
    })
  
import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import CommonButton from './CommonButton'
import loaderToyota from '../images/toyota.loader.gif'
import loaderLexus from '../images/loader-lexus.gif'
import ParagrafTailwind from './Paragraf'
import BottomModal from './BottomModal'
import Title from './Title'
import { classNames } from 'utils/css'
import { isLexusUrl, isLexusUrlStatic } from 'utils/utils'

let loader = loaderToyota
let styleLexus = {  };
let url = window.location.href;
if(isLexusUrlStatic(url)){
  loader = loaderLexus;
  styleLexus = { height:'7rem' };
}

type UploadFileProps = {
  loadingApi?: boolean
  setPicture?: (data: {
    file: HTMLInputElement['files'][0]
    type: string
  }) => void
  uploadingProcess: number
}


const UploadFile: React.FC<UploadFileProps> = ({
  loadingApi,
  setPicture,
  uploadingProcess,
}) => {
  const navigate = useNavigate()
  const [isStepModalOpen, setIsStepModalOpen] = useState(false)
  const inputFileRef = useRef(null)



  const onClickButton = () => {
    handleOnDismissModal()
    setTimeout(() => {
      inputFileRef.current?.click()
    }, 300)
  }

  const onClickButtonPicture = () => {
    navigate('/picture-explanation')
  }
  const openModal = () => setIsStepModalOpen(true)
  const handleOnDismissModal = () => setIsStepModalOpen(false)

  const handleInputChange = async e => {
    setPicture({ file: e.target.files[0], type: 'PDF' })
  }

  const [backgroundAlertColor, setbackgroundAlertColor] = useState("")
  useEffect(() => {
    let url = window.location.href;
    isLexusUrl(url).then(isLexus => {
      if (isLexus) {
        setbackgroundAlertColor("bg-lexusBlue")
      } else {
        setbackgroundAlertColor("bg-primary")
      }
    })
  }, [])

  return (
    <>
      {loadingApi ? (
        <>
          <div>
            <img src={loader} alt="Logo" className={styleLexus['height'] ? "" : "h-12"} style={styleLexus} />
          </div>
          <div>
            <ParagrafTailwind name="Caricamento del documento in corso..." />
          </div>
        </>
      ) : (
        <>
          <div>
            <CommonButton onClick={openModal} type="button" name="Carica PDF" />
          </div>
          <div className="mt-8">
            <CommonButton
              onClick={onClickButtonPicture}
              type="button"
              name="Scatta foto"
            />
          </div>
          <div className="mt-8">
            <CommonButton
              onClick={() => (window.location.href = '/customer-area')}
              type="button"
              name="Area Cliente"
            />
          </div>
        </>
      )}
      <div>
        {uploadingProcess ? (
          <span className="text-third">
            Progresso caricamento: {uploadingProcess.toFixed(0)} %
          </span>
        ) : null}
      </div>

      <input
        type="file"
        id="file"
        style={{ display: 'none' }}
        accept="application/pdf"
        ref={inputFileRef}
        onChange={handleInputChange}
      />


      <BottomModal isOpen={isStepModalOpen} onDismiss={handleOnDismissModal}>
        <div className="text-center space-y-5 py-3">
          <Title name="Avviso" />
          <div
            className={classNames("flex items-center text-white text-sm font-bold px-4 py-3", backgroundAlertColor)}
            role="alert"
          >
            <svg
              className="fill-current w-4 h-4 mr-2"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path d="M12.432 0c1.34 0 2.01.912 2.01 1.957 0 1.305-1.164 2.512-2.679 2.512-1.269 0-2.009-.75-1.974-1.99C9.789 1.436 10.67 0 12.432 0zM8.309 20c-1.058 0-1.833-.652-1.093-3.524l1.214-5.092c.211-.814.246-1.141 0-1.141-.317 0-1.689.562-2.502 1.117l-.528-.88c2.572-2.186 5.531-3.467 6.801-3.467 1.057 0 1.233 1.273.705 3.23l-1.391 5.352c-.246.945-.141 1.271.106 1.271.317 0 1.357-.392 2.379-1.207l.6.814C12.098 19.02 9.365 20 8.309 20z" />
            </svg>
            <p>
              per una buona riuscita, assicurarsi di caricare solo il documento
              identificato come "ordine di lavoro"
            </p>
          </div>
          <CommonButton name="Continua" onClick={onClickButton} />
        </div>
      </BottomModal>
    </>
  )
}

export default UploadFile

import CommonButton from 'components/CommonButton'
import Paragraf from 'components/Paragraf'
import Title from 'components/Title'
import { useQuery } from 'Hooks/useQuery'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { HOMEPAGE, KO } from 'routes/constants'
import { populateWorkOrder } from 'store/slices/appMiscSlice'

const Ko: React.VFC<{
  errorMap: Map<string, string>
}> = ({ errorMap }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const query = useQuery()

  useEffect(() => {
    const errorCode = query.get('errorCode')
    if (errorCode) {
      navigate(`/${KO}`, { replace: true })
      toast.error(
        errorMap.has(errorCode)
          ? errorMap.get(errorCode)
          : errorMap.get('default'),
      )
    }
  }, [])

  const handleNewOperation = async () => {
    await dispatch(populateWorkOrder({ workOrder: null, status: null }))
    navigate(`/${HOMEPAGE}`)
  }
  return (
    <div className="mb-2 text-left w-full px-5 pb-5">
      <Title name="..OPS! qualcosa è andato storto" cssProps="text-2xl" />
      <Paragraf name="Non è stato possibile effettuare il pagamento, Per favore riprova più tardi" />
      <div className="mt-5 mb-10">
        <CommonButton
          onClick={handleNewOperation}
          type="button"
          name="Effettua nuova operazione"
        />
      </div>
    </div>
  )
}

export default Ko

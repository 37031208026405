/* eslint-disable no-param-reassign */
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { LegalDoc, LegalDocFormat } from 'commons/dto/legalDoc'
import { NavigateFunction } from 'react-router-dom'
import { RootState } from 'store'
import { WorkOrder, WorkOrderStatus } from 'store/types/workOrder'

interface AppMiscState {
  showLoadingBackdrop: boolean
  workOrder: WorkOrder & { status: WorkOrderStatus }
  legalDocs: {
    [documentId: string]: {
      loading: boolean
      error: boolean
      content: LegalDoc & { content: string }
    }
  }
}

export const initialState = {
  showLoadingBackdrop: false,
  workOrder: null,
  legalDocs: {},
} as AppMiscState

const appMiscSlice = createSlice({
  name: 'appMisc',
  initialState,
  reducers: {
    toggleLoading(state, action: PayloadAction<{ value: boolean }>) {
      state.showLoadingBackdrop = action.payload.value
    },
    populateWorkOrder(
      state,
      action: PayloadAction<{ workOrder: WorkOrder; status: WorkOrderStatus }>,
    ) {
      const { workOrder, status } = action.payload
      state.workOrder = workOrder == null ? null : { ...workOrder, status }
    },
    getLegalDoc(
      state,
      action: PayloadAction<{
        documentId: string
        documentFormat: LegalDocFormat
        navigate: NavigateFunction
      }>,
    ) {
      const { documentId } = action.payload
      state.legalDocs[documentId] = {
        loading: true,
        error: false,
        content: null,
      }
    },
    getLegalDocSucceeded(
      state,
      action: PayloadAction<{
        documentId: string
        document: LegalDoc & {
          content: string
        }
      }>,
    ) {
      const { documentId, document } = action.payload
      state.legalDocs[documentId] = {
        loading: false,
        error: false,
        content: document,
      }
    },
    getLegalDocFailed(state, action: PayloadAction<{ documentId: string }>) {
      const { documentId } = action.payload
      state.legalDocs[documentId] = {
        loading: false,
        error: true,
        content: null,
      }
    },
    subscribeLegalDoc(
      _state,
      _action: PayloadAction<{
        documentId: string
        targetPath: string
        errorTargetPath?: string
        navigate: NavigateFunction
      }>,
    ) {},
    completeRegistrationProcess(
      _state,
      _action: PayloadAction<{
        navigate: NavigateFunction
      }>,
    ) {},
    subscribeToAllLegalDocs(
      _state,
      _action: PayloadAction<{
        navigate: NavigateFunction
      }>,
    ) {},
    resetToInitialStateAppMisc(state) {
      state.showLoadingBackdrop = false
      state.workOrder = null
      state.legalDocs = {}
    },
  },
})

export const selectWorkOrder = (state: RootState) => state.appMisc.workOrder
export const selectLegalDocs = (state: RootState) => state.appMisc.legalDocs
export const selectShowLoadingBackdrop = (state: RootState): boolean =>
  state.appMisc.showLoadingBackdrop
export const selectLegalDocById = createSelector(
  [
    // Usual first input - extract value from `state`
    (state: RootState) => state.appMisc.legalDocs,
    // Take the second arg, `documentId`, and forward to the output selector
    (state, documentId) => documentId,
  ],
  // Output selector gets (`legalDocs, documentId)` as args
  (legalDocs, documentId) => legalDocs[documentId],
)

export const {
  toggleLoading,
  populateWorkOrder,
  getLegalDoc,
  getLegalDocSucceeded,
  getLegalDocFailed,
  subscribeLegalDoc,
  completeRegistrationProcess,
  subscribeToAllLegalDocs,
  resetToInitialStateAppMisc,
} = appMiscSlice.actions
export default appMiscSlice.reducer

import { useSelector } from 'react-redux'
import './App.css'
import LoadingBackdrop from 'components/LoadingBackdrop'
import Toast from 'components/Toast'
import Routes from 'routes'
import { selectShowLoadingBackdrop } from 'store/slices/appMiscSlice'
import { selectUserMiscLoading } from 'store/slices/userSlice'
import { isLexusUrl } from 'utils/utils'

function getFaviconEl() {
  return document.getElementById("favicon");
}

const App = () => {
  const loadingAppMisc = useSelector(selectShowLoadingBackdrop)
  const loadingUserMisc = useSelector(selectUserMiscLoading)

  let url = window.location.href;
  isLexusUrl(url).then(isLexus => {
  if(isLexus) {
    document.title = 'Lexus easy pay';
    const favicon = getFaviconEl(); 
    favicon.setAttribute('href', '/favicon-Lexus.ico');
  } else {
    document.title = 'Toyota easy pay';
    const favicon = getFaviconEl(); 
    favicon.setAttribute('href', '/favicon.ico');
  }})

  return (
    <div>
      <Toast />
      {(loadingAppMisc || loadingUserMisc) && <LoadingBackdrop />}
      <Routes />
    </div>
  )
}

export default App

import { AxiosResponse } from 'axios'
import { easyPayInstance } from 'Axios/AxiosInstance'
import {
  ActivateSubscriptionResponse,
  CreateSubscriptionResponse,
} from 'commons/dto/subscription'

  let appId = "TOYOTA"
  let url = window.location.href;

export const createSubscription = (
  workOrderId: Record<string, string>,
): Promise<AxiosResponse<CreateSubscriptionResponse>> => {
  return easyPayInstance.post(`/${appId}/subscription`, {
    ...workOrderId,
  })
}

export const subscriptionExecution = ({
  subscriptionId,
  customerId,
}: {
  subscriptionId: string
  customerId: Record<string, string>
}): Promise<AxiosResponse<ActivateSubscriptionResponse>> => {
  return easyPayInstance.post(`/${appId}/subscription/${subscriptionId}/activate`, {
    customerId,
  })
}

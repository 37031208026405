import React from 'react'
import loaderToyota from '../images/toyota.loader.gif'
import loaderLexus from '../images/loader-lexus.gif'
import { isLexusUrlStatic } from 'utils/utils'

let loader = loaderToyota
let url = window.location.href;
let loaderStyle: { margin?: string, width?: number, height?: string } = { margin: '10px auto', width: 50 };
if (isLexusUrlStatic(url)) {
  loader = loaderLexus
  loaderStyle = { margin: '10px auto', height: '7rem' };
}


const style = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100vw',
  height: '100vh',
  backgroundColor: '#FFF',
  opacity: '0.85',
  zIndex: 9999,
} as React.CSSProperties

const LoadingBackdrop: React.VFC = () => (
  <div style={style}>
    <img src={loader} style={loaderStyle} alt="" />
  </div>
)

export default LoadingBackdrop

/**
 * It takes an array of strings and booleans, filters out the booleans, and joins the strings with a
 * space
 * @param classes - Array<string | boolean>
 * @returns A function that takes in an array of strings and booleans and returns a string of the
 * strings that are true.
 */
export function classNames(...classes: Array<string | boolean>): string {
  return classes.filter(Boolean).join(' ')
}

import React, { useEffect, useState} from 'react'
import LoadingButton from './LoadingButton'
import { classNames } from 'utils/css'
import { isLexusUrl } from 'utils/utils'

type Props = {
  name?: string
  loadingApi?: boolean
  cssProps?: string
  onClick?: VoidFunction
  type?: 'button' | 'submit' | 'reset'
  disabled?: boolean,
  cssType?: 'fill-in-white'
}

const CommonButton: React.FC<Props> = ({
  name,
  loadingApi,
  cssProps,
  onClick,
  type,
  disabled = false,
  cssType,
}) => {

  const [css, setCss] = useState("py-2 px-5 mt-2 border-2 rounded-full text-base mx-auto block w-64 ")

  useEffect(() => {
    //debugger
    let url = window.location.href;
    isLexusUrl(url).then(isLexus => {
    if(isLexus) {
      if(cssType == "fill-in-white") {
        setCss("bg-white text-lexusBlue " + css + " border-lexusBlue font-LexusTypeRegular")
      }else {
        setCss("bg-lexusBlue text-white " + css + " border-lexusBlue font-LexusTypeRegular")
      }
    } else {
      if(cssType == "fill-in-white") {
        setCss("bg-white text-third " + css + " border-primary")

      }else {
        setCss("bg-primary text-white " + css + " border-primary")
      }
    }})


  },[])

  /*
    La parte inquinata da me:
    useEffect(() => {

    setCss(isLexus("cssTypeHandler", cssType+";"+css))

  },[css, cssType])
  */


  return (
    <>
      {loadingApi ? (
        <LoadingButton />
      ) : (
        <button
          disabled={disabled}
          className={classNames(
            cssProps ||
                css,
            disabled && 'opacity-25',
          )}
          type={type ?? 'submit'}
          onClick={onClick}
        >
          {name}
        </button>
      )}
    </>
  )
}

export default CommonButton

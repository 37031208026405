/* eslint-disable no-shadow */
import rootSaga from 'containers/App/saga'
import createSagaMiddleware from 'redux-saga'
import appMiscReducer, {
  initialState as appMiscInitialState,
} from './slices/appMiscSlice'
import registrationStepperReducer from './slices/registrationStepper'
import userReducer, {
  initialState as userInitialState,
} from './slices/userSlice'

import { combineReducers, configureStore, Unsubscribe } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
const sagaMiddleware = createSagaMiddleware()

const getAppMiscState = () => {
  const appMisc = JSON.parse(sessionStorage.getItem('appMisc') || '{}')
  const wordOrder = JSON.parse(
    sessionStorage.getItem('easyPay') || '{}',
  )?.workOrder
  const newAppMisc = {
    ...appMisc,
    workOrder: wordOrder,
  }
  return newAppMisc
}

const getUserState = () => {
  const user = JSON.parse(sessionStorage.getItem('user') || '{}')
  return user
}

const reducer = combineReducers({
  appMisc: appMiscReducer,
  user: userReducer,
  registrationStepper: registrationStepperReducer,
})
export const store = configureStore({
  reducer,
  preloadedState: {
    appMisc: { ...appMiscInitialState, ...getAppMiscState() },
    user: { ...userInitialState, ...getUserState() },
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({ thunk: false, serializableCheck: false }).concat(
      sagaMiddleware,
    ),
})

const setAppMisc = () => {
  const appMisc = store.getState()?.appMisc
  sessionStorage.setItem('appMisc', JSON.stringify(appMisc))
}

const setUser = () => {
  const user = store.getState()?.user
  sessionStorage.setItem('user', JSON.stringify(user))
}

export const initStoreSubscription = (): {
  appMiscStoreUnsubscribe: Unsubscribe
  userStoreUnsubscribe: Unsubscribe
} => {
  const appMiscStoreUnsubscribe = store.subscribe(() => setAppMisc())
  const userStoreUnsubscribe = store.subscribe(() => setUser())
  return { appMiscStoreUnsubscribe, userStoreUnsubscribe }
}
export const { appMiscStoreUnsubscribe, userStoreUnsubscribe } =
  initStoreSubscription()

sagaMiddleware.run(rootSaga)

export const unsubscribeStore = setupListeners(store.dispatch)
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
export default store

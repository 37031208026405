import CommonButton from 'components/CommonButton'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  CUSTOMER_AREA_INFORMATIVE_DOCUMENTS,
  CUSTOMER_AREA_PAYMENTS_SITUATION,
  PAYMENT_METHOD,
} from 'routes/constants'
import { logOutRequest } from 'store/slices/userSlice'
import { isLexusCss,isLexusUrl } from 'utils/utils'


  let urlisLexus = []
  let url = window.location.href;
  isLexusCss(url).then(isLexus => {
      urlisLexus=isLexus
  })

const CustomerArea: React.FC = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [css, setCss] = useState(`rounded-full py-2 px-5 text-white text-base font-${urlisLexus[0]} mx-auto block w-64`)

  useEffect(()=> {
    let url = window.location.href;
    isLexusUrl(url).then(isLexus => {
      if(isLexus) {
        setCss("bg-lexusBlue " + css)
      } else {
        setCss("bg-primary " + css)
      }
    })
  }, [])


  const customerButtons = [
    {
      text: 'Situazione pagamenti',
      onClick: () =>
        navigate(`/customer-area/${CUSTOMER_AREA_PAYMENTS_SITUATION}`),
    },
    // {
    //   text: 'Paga una Rata',
    //   onClick: () => navigate(`/customer-area/${CUSTOMER_AREA_PAY_INSTALMENT}`),
    // },
    // {
    //   text: 'Pagamenti Effettuati',
    //   onClick: () => navigate(`/customer-area/${CUSTOMER_AREA_PAYMENTS_MADE}`),
    // },
    {
      text: 'Metodi di Pagamento',
      onClick: () => navigate(`/customer-area/${PAYMENT_METHOD}`),
    },
    {
      text: 'Dati Personali',
      onClick: () => navigate('/customer-area/profile'),
    },
    {
      text: 'Cambio Password',
      onClick: () => navigate('/customer-area/update/password'),
    },
    {
      text: 'Scarica documenti',
      onClick: async () =>
        navigate(`/customer-area/${CUSTOMER_AREA_INFORMATIVE_DOCUMENTS}`),
    },
    {
      text: 'Logout',
      onClick: () => dispatch(logOutRequest({ targetPath: '/', navigate })),
    },
  ]
  return (
    <div className="mb-2 text-left w-full px-5 pb-5">
      <div className="mx-auto flex flex-col space-y-8">
          {customerButtons.map(({ text, onClick }, index) => (
            <CommonButton
              key={index}
              type="button"
              name={text}
              onClick={onClick}
              cssProps={css}
            />
          ))}

      </div>


    </div>
    
  )
}

export default CustomerArea

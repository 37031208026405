import { AxiosResponse } from 'axios'
import { CardResponse } from 'commons/dto/cards'
import { easyPayInstance } from 'Axios/AxiosInstance'
import React, { useEffect} from 'react'
import { getBrandByURL, isLexusUrlStatic } from 'utils/utils'

  let brand = "TOYOTA"
  let appId = "TOYOTA"
  let url = window.location.href;
  
  if(isLexusUrlStatic(url)){
    brand="LEXUS";
  }

export const getCards = (
  customerId: string,
): Promise<AxiosResponse<Array<CardResponse>>> =>
  easyPayInstance.get(`/${appId}/paymentMethod/`, {
    params: {
      customerId,
    },
  })

export const activateCard = (cardId: string): Promise<AxiosResponse<void>> =>
  easyPayInstance.patch(`/${appId}/paymentMethod/`, {
    cardId,
    status: 'ACTIVE',
  })
export const suspendCard = (cardId: string): Promise<AxiosResponse<void>> =>
  easyPayInstance.patch(`/${appId}/paymentMethod/`, {
    cardId,
    status: 'SUSPENDED',
  })
export const cancelCard = (cardId: string): Promise<AxiosResponse<void>> =>
  easyPayInstance.patch(`/${appId}/paymentMethod/`, {
    cardId,
    status: 'CANCELED',
  })

export const addNewCard = (
  customerId: string,
): Promise<AxiosResponse<{ url: string }>> =>
  easyPayInstance.post(`/${appId}/paymentMethod/`, {
    customerId,
    brand
  })

import BottomModal from 'components/BottomModal'
import CommonButton from 'components/CommonButton'
import FAB from 'components/FAB'
import Paragraf from 'components/Paragraf'
import Stepper from 'components/Stepper'
import Title from 'components/Title'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { populateWorkOrder } from 'store/slices/appMiscSlice'

const STEPS = ['Dettaglio ordine', 'checkout', 'fine transazione']
const CURRENT_STEP = 3
const TYPFinancial: React.VFC = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [isModalOpen, setIsModalOpen] = useState(false)
  const handleOpenStepper = () => setIsModalOpen(true)

  const handleOnDismissModal = () => {
    setIsModalOpen(false)
  }

  const handleNewOperation = async () => {
    await dispatch(populateWorkOrder({ workOrder: null, status: null }))
    navigate('/')
  }

  return (
    <div className="mt-8 mb-8 pl-2 pr-2 mx-5">
      <Title name="Pagamento approvato" cssProps="text-2xl" />
      <Paragraf
        cssProps="text-center"
        name="Congratulazioni, l'operazione è avvenuta con successo!"
      />
      <div className="mt-8">
        <CommonButton
          type="button"
          name="Nuova operazione"
          onClick={handleNewOperation}
        />
      </div>
      <div className="mt-8">
        <CommonButton
          type="button"
          name="Area cliente"
          onClick={() => navigate('/customer-area/homepage')}
        />
      </div>
      <FAB onClick={handleOpenStepper} />
      <BottomModal isOpen={isModalOpen} onDismiss={handleOnDismissModal}>
        <Stepper stepsLabels={STEPS} currentStep={CURRENT_STEP} />
      </BottomModal>
    </div>
  )
}

export default TYPFinancial

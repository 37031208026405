import { yupResolver } from '@hookform/resolvers/yup'
import CommonButton from 'components/CommonButton'
import Input from 'components/Input'
import React from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import * as Yup from 'yup'

import { addressApi, userApi } from 'api/easypay'
import moment from 'moment'
import { RootState } from 'store'
import {
  logOutRequest,
  updateAddressInfo,
  updateUserInfo,
} from 'store/slices/userSlice'
import { printToast } from 'utils/printToast'
import { isLexusCss, isLexusUrl } from 'utils/utils'


  

  

const canUpdateUserData = false

const postalCodeRegex = /\d{5}/

const getGenderFromTaxCode = (taxCode: string) => {
  const birthDay = taxCode.substring(9, 11)
  if (Number.isNaN(Number(birthDay)))
    printToast.error(
      `Il tuo codice fiscale [${taxCode}] non sembra valido. Contatta l'assistenza clienti.`,
    )
  return Number(birthDay) >= 40 ? 'F' : 'M'
}
  let urlisLexus = []
let url = window.location.href;
  isLexusCss(url).then(isLexus => {
     urlisLexus=isLexus
  })

const Profile: React.FC = () => {

  
  

  var css;
  isLexusUrl(url).then(isLexus => {
    if(isLexus) {
      css = "border-lexusBlue text-lexusBlue "
    } else {
      css = "border-primary text-third "
    }
  })
  


  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { userInfo, addressInfo, phoneInfo } = useSelector(
    (state: RootState) => state.user,
  )

  if (!userInfo || !userInfo.userId || !addressInfo) {
    printToast.error(undefined, undefined, () =>
      dispatch(logOutRequest({ targetPath: '/login', navigate })),
    )
    return null
  }

  const validationSchema = Yup.object().shape({
    postalCode: Yup.string()
      .required('Campo richiesto')
      .matches(postalCodeRegex, 'Campo non valido'),
    country: Yup.string().required('Campo richiesto'),
    city: Yup.string().required('Campo richiesto'),
    streetNumber: Yup.number()
      .transform(value => (Number.isNaN(value) ? undefined : value))
      .required('Campo richiesto')
      .positive('Campo non valido'),
    streetName: Yup.string().required('Campo richiesto'),
  })
  const {
    register,
    handleSubmit,
    trigger,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'all',
    defaultValues: userInfo && {
      name: userInfo.name,
      surname: userInfo.surname,
      fiscalCode: userInfo.taxCode,
      nationality: userInfo.nationality,
      birthDate: userInfo.dateOfBirth,
      birthPlace: userInfo.placeOfBirth,
      streetName: addressInfo.streetAddress,
      streetNumber: addressInfo.streetNumber,
      country: addressInfo.country,
      city: addressInfo.city,
      postalCode: addressInfo.postalCode,
    },
  })

  const handleOnBlur = inputName => {
    trigger(inputName)
  }

  const onSubmit = async ({
    birthDate,
    birthPlace,
    city,
    country,
    fiscalCode,
    name,
    nationality,
    postalCode,
    streetName,
    streetNumber,
    surname,
  }) => {
    try {
      if (canUpdateUserData) {
        await userApi.updatePersonalInfo(
          userInfo.userId,
          birthDate,
          getGenderFromTaxCode(fiscalCode),
          name,
          nationality,
          birthPlace,
          surname,
          fiscalCode.toUpperCase(),
        )
        dispatch(
          updateUserInfo({
            name,
            surname,
            dateOfBirth: birthDate,
            placeOfBirth: birthPlace,
            nationality,
            taxCode: fiscalCode.toUpperCase(),
          }),
        )
      }
      await addressApi.updateAddress(
        addressInfo.addressId,
        city,
        country,
        postalCode,
        streetName,
        streetNumber,
      )
      dispatch(
        updateAddressInfo({
          city,
          country,
          postalCode,
          streetAddress: streetName,
          streetNumber,
        }),
      )
      printToast.success('I dati sono stati aggiornati correttamente', 3000)
    } catch (e) {
      console.error(e)
      printToast.error()
    }
  }

  return (
    <div className="mb-2 text-left w-full px-5 pb-5">
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <div className="space-y-2 mb-4">
          <div>
            <p className={`font-${urlisLexus[0]} text-primary `}>Nome</p>
            <p className={`font-${urlisLexus[0]}  `}>{userInfo.name}</p>
          </div>
          <div>
            <p className={`font-${urlisLexus[0]} text-primary `}>Cognome</p>
            <p className={`font-${urlisLexus[0]}  `}>{userInfo.surname}</p>
          </div>
          <div>
            <p className={`font-${urlisLexus ? "LexusTypeRegular" : "ToyotaTypeLight"} text-primary `}>
              Codice fiscale
            </p>
            <p className={`font-${urlisLexus[0]}  `}>{userInfo.taxCode}</p>
          </div>
          <div>
            <p className={`font-${urlisLexus[0]} text-primary `}>Nazionalità</p>
            <p className={`font-${urlisLexus[0]}  `}>{userInfo.nationality}</p>
          </div>
          <div>
            <p className={`font-${urlisLexus[0]} text-primary `}>
              Data di nascita
            </p>
            <p className={`font-${urlisLexus[0]}  `}>
              {moment(userInfo.dateOfBirth).format('DD-MM-YYYY')}
            </p>
          </div>
          <div>
            <p className={`font-${urlisLexus[0]} text-primary `}>
              Luogo di nascita
            </p>
            <p className={`font-${urlisLexus[0]}  `}>{userInfo.placeOfBirth}</p>
          </div>
        </div>

        <div className="flex justify-between gap-2 space-x-4 mb-5">
          <div>
            <Input
              register={register}
              name="streetName"
              autoComplete="address-line1"
              onBlur={() => handleOnBlur('streetName')}
              error={errors?.streetName}
              customLabel="Via"
            />
          </div>

          <div>
            <Input
              register={register}
              name="streetNumber"
              error={errors?.streetNumber}
              customLabel="Numero civico"
              onBlur={() => handleOnBlur('streetNumber')}
            />
          </div>
        </div>
        <div className="flex justify-between gap-2 space-x-4 mb-5">
          <div>
            <Input
              register={register}
              name="country"
              autoComplete="country-name"
              error={errors?.country}
              onBlur={() => handleOnBlur('country')}
              customLabel="Nazione"
            />
          </div>

          <div>
            <Input
              register={register}
              name="city"
              error={errors?.city}
              onBlur={() => handleOnBlur('city')}
              customLabel="Città"
            />
          </div>

          <div>
            <Input
              register={register}
              name="postalCode"
              autoComplete="postal-code"
              error={errors?.postalCode}
              onBlur={() => handleOnBlur('postalCode')}
              customLabel="Cap"
            />
          </div>
        </div>
        <hr className="my-4" />
        <div className="my-8">
          <div className="flex justify-between items-center mb-2">
            <span>Email</span>
            <button
              onClick={() => {
                navigate('/customer-area/update/email')
              }}
              className={css + "border-2 rounded-full py-2 px-5 text-base uppercase font-bold"}
            >
              Cambia
            </button>
          </div>
          <span className="font-bold">{userInfo.email}</span>
        </div>
        <hr />
        <div className="my-8">
          <div className="flex justify-between items-center">
            <span>Telefono</span>
            <button
              onClick={() => {
                navigate('/customer-area/update/phone')
              }}
              className={css + "border-2 rounded-full py-2 px-5 text-base uppercase font-bold"}
            >
              Cambia
            </button>
          </div>
          <span className="font-bold">{phoneInfo.phoneNumber}</span>
        </div>
        <hr className="my-4" />
        <div className="space-y-4">
          <CommonButton type="submit" name="Salva" />
          <CommonButton
            type="button"
            name="Indietro"
            cssType='fill-in-white'
            onClick={() => {
              navigate('/customer-area/homepage')
            }}
          />
        </div>
      </form>
    </div>
  )
}

export default Profile

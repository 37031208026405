import CommonButton from 'components/CommonButton'
import Paragraf from 'components/Paragraf'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { selectWorkOrder } from 'store/slices/appMiscSlice'

const ChooseOption: React.FC = () => {
  const navigate = useNavigate()
  const workOrder = useSelector(selectWorkOrder)

  useEffect(() => {
    if (workOrder && Object.entries(workOrder).length === 0) {
      navigate('/')
    }
  }, [workOrder, history])

  return (
    <div className="mb-2 text-left w-full px-5 pb-5">
      <Paragraf name="Scegli una procedura per poter iniziare il pagamento" />
      <div className="mb-2 mt-10 text-center w-full ">
        <CommonButton
          name="Pagamento unico"
          onClick={() => navigate('/direct-payment')}
          // cssProps={
          //   "bg-primary border-2 border-primary rounded-full py-2 px-5 w-full text-base text-white"
          // }
        />
      </div>
      <div className="mt-10 text-center w-full ">
        <CommonButton
          name="Tre rate senza spese"
          //cssProps="bg-secondary border-2 border-primary rounded-full py-2 px-5 w-64 text-base text-third"
          onClick={() => navigate('/split-payment-overview')}
          cssType='fill-in-white'
        />
      </div>
    </div>
  )
}

export default ChooseOption

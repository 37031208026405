import React from 'react'
import { isLexusCss } from 'utils/utils'


  let urlisLexus = []
  let url = window.location.href;
  isLexusCss(url).then(isLexus => {
      urlisLexus=isLexus
  })
type GoBackButtonProps = {
  handleClick: () => void
  cssProps?: string
}
const GoBackButton: React.VFC<GoBackButtonProps> = ({
  handleClick,
  cssProps,
}) => {
  return (
    <button
      type="button"
      onClick={handleClick}
      className={
        cssProps ??
        `py-2 px-5 flex space-x-2 justify-center items-center font-${urlisLexus[0]} mx-auto w-64 `
      }
    >
      <img src="/assets/icons/left-arrow.svg" alt="icona" />
      <span className="text-ToyotaSignatureGrey">Torna indietro</span>
    </button>
  )
}

export default GoBackButton

import { emailApi } from 'api/easypay'
import { __DEV__ } from 'commons/constants'
import FormConfirmEmail from 'components/FormConfirmEmail'
import FormPinPad from 'components/FormPinPad'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  selectWorkOrder,
  subscribeToAllLegalDocs,
  toggleLoading,
} from 'store/slices/appMiscSlice'
import {
  decrementCurrentStep,
  incrementCurrentStep,
} from 'store/slices/registrationStepper'
import {
  selectTempUser,
  setTempUserIdRequest,
  setUserEmail as setUserEmailAction,
} from 'store/slices/userSlice'
import { printToast } from 'utils/printToast'
import { isLexusUrl } from 'utils/utils'

const EmailSubmission: React.FC = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [section, setSection] = useState<'form' | 'confirm-code'>('form')
  const [userEmail, setUserEmail] = useState<string>(null)
  const workOrder = useSelector(selectWorkOrder)
  const { id: tempUserId } = useSelector(selectTempUser)

  const [deletingMail, setDeletingMail] = useState<string>(null)
  let brand = "TOYOTA"
  let url = window.location.href;
  isLexusUrl(url).then(isLexus => {
  if(isLexus) {
    brand = "LEXUS"
  } 
  })

  useEffect(()=>{
    if(sessionStorage.getItem("userEmailVerified")){
      sessionStorage.removeItem("user");
      dispatch(setTempUserIdRequest({ navigate }));
      sessionStorage.removeItem("userEmailVerified");
    }
  })

  if (!workOrder || !Object.entries(workOrder).length) {
    console.log('ordine di lavoro non trovato')
    printToast.error(undefined, undefined, () => navigate('/'))
  }

  const onSubmitConfirmEmail = async (data: { email: string }) => {
    dispatch(toggleLoading({ value: true }))
    try {
      let email = data.email ?? data.email.replace(" ", "")
      await emailApi.insertEmail(tempUserId, email, true, brand)
      setUserEmail(email)
      setSection('confirm-code')
    } catch (e) {
      console.error(e)
      const message = JSON.parse(e?.response?.data?.messages[0]?.message)
      if (message.code === 'USED_EMAIL') {
        printToast.error(message.message, 6000)
      } else {
        printToast.error(
          "Qualcosa è andato storto, prova a reinserire l'email",
          5000,
        )
      }
    } finally {
      dispatch(incrementCurrentStep())
      dispatch(toggleLoading({ value: false }))
    }
  }

  const onConfirmCode = async ({ confirmCode }) => {
    dispatch(toggleLoading({ value: true }))
    try {
      await emailApi.confirmEmail(tempUserId, confirmCode.trim(), userEmail)
      await dispatch(setUserEmailAction({ email: userEmail }))
      await dispatch(subscribeToAllLegalDocs({ navigate }))
      let item = true;
      sessionStorage.setItem("userEmailVerified", item.toString());
      navigate('/registration/phone-submission')
    } catch (e) {
      try {
        const message = JSON.parse(e?.response?.data?.messages[0]?.message)
        if (message?.code === "04") {
          printToast.error(message.message, 6000)
        }else {        
          printToast.error('Qualcosa è andato storto',5000,)
        }
      } catch(e) {
        console.error("Could not parse " + e)
        printToast.error(
          'Qualcosa è andato storto',
          5000,
        )
      }
    } finally {
      dispatch(incrementCurrentStep())
      dispatch(toggleLoading({ value: false }))
    }
  }

  const onResendOtp = async () => {
    dispatch(toggleLoading({ value: true }))
    try {
      await emailApi.resendOtpEmail(tempUserId, userEmail, brand)
      printToast.success(
        'Richiesta inviata. Controlla la tua casella di posta.',
      )
    } catch (e) {
      console.error(e)
      printToast.error(
        `Errore nell' invio della richiesta. Riprovare più tardi.`,
      )
    } finally {
      dispatch(toggleLoading({ value: false }))
    }
  }
  const handleFormPinPadGoBack = () => {
    dispatch(decrementCurrentStep())
    setSection('form')
  }

  const RenderSectionsMap =
    workOrder &&
    new Map([
      [
        'form',
        <FormConfirmEmail
          key="form"
          workOrderEmail={workOrder.email}
          handleConfirmEmailSubmit={onSubmitConfirmEmail}
        />,
      ],
      [
        'confirm-code',
        <FormPinPad
          key="confirm-code"
          header="Conferma codice"
          subHeader={`Inserisci il codice di conferma che hai ricevuto all'email ${userEmail} `}
          onPinPadSubmit={onConfirmCode}
          onResendConfirmationCode={onResendOtp}
          onGoBack={handleFormPinPadGoBack}
        />,
      ],
    ])

  if (!workOrder) return null
  if (__DEV__ && section === 'form')
    return (
      <>
        <div
          style={{
            border: '1px solid #FF0000',
            width: '100%',
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
          }}
          onClick={async () => {
            await emailApi.deleteEmail(deletingMail)
          }}
        >
          <input
            placeholder="Insert address"
            onChange={e => setDeletingMail(e.target.value)}
          />
          <strong>DELETE EMAIL</strong>
        </div>
        {RenderSectionsMap.get(section)}
      </>
    )
  return RenderSectionsMap.get(section)
}

export default EmailSubmission
